import React from 'react';
import puzzle from 'Assets/illustrations/puzzle.svg';

import styles from './AboutUs.module.scss';
import { teamMembers } from './teamMembers';
import { openInNewTab } from 'Helpers/uiFunctions';

const AboutUs = () => {

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <h2 className={styles.title}>About Faith Journals</h2>

        <div className={styles.howItStarted}>
          <div className={styles.imgContainer}>
            <img src={puzzle} alt="puzzle" />
          </div>
          <div className={styles.text}>
            <h3>How it started</h3>
            <h6>Faith Journals is one of those “coincidences” that God made happen in a beautiful way.</h6>
            <p>A small, dedicated team worked together for years to build a superior writing software platform. This platform became used by more than 450,000 teachers and students in classrooms around the world. One day, in mid-2020, we decided to do a pilot in one of our classrooms using our software with the Word of God. The teacher was excited to use it to help students read, reflect and write on their faith journey. The results were so astonishing that we decided to start a new company with a mission of strengthening people’s faith as the means to make a difference in the world.</p>
            <p className={styles.italic}> Faith Journals, a project of escribli, inc., was officially born in Q3 2021.</p>
            <div className={styles.interviewDiv}>
              <h6>Watch our interview at <span>Catholic.tv</span></h6>
              <div onClick={()=>openInNewTab('https://www.youtube.com/watch?v=kRFrrINE0fg&t')} className={styles.videoDiv}>
                <div className={styles.play} />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.meetTheTeam}>
          <h3 className={styles.teamTitle}>Meet the team</h3>
          <div className={styles.teamMembers}>
            {
              teamMembers.map((person, idx) =>
                <div key={idx} className={styles.teamMemberBlock}>
                  <img src={person.image} alt={person.name} />
                  <p>{person.name}</p>
                  <p className={styles.title}>{person.title}</p>
                </div>
              )
            }
            <div className={styles.phantomMember} />
            <div className={styles.phantomMember} />
            <div className={styles.phantomMember} />
          </div>
        </div>
      </div>
    </div>
  )

}

export default AboutUs;