import React from 'react';
import { useSelector } from 'react-redux';

import styles from './checkoutStyles.module.scss';

const OrderDetailBlock = ({ summaryDetails, setSummaryDetails, setStage, stepThree, purchaseComplete}) => {

  const bookType = useSelector(state => state.storeData.bookDetails.type)

  return (

    <div className={`${styles.orderSummary} ${stepThree ? styles.stepThree:null}`}>

      <div className={styles.colorTab}></div>
      <h3>Order details:</h3>
      {!purchaseComplete && <p onClick={()=>{setStage(1); setSummaryDetails({...summaryDetails, creditsRedeemed:0})}} className={styles.editBtn}>Edit order</p>}
      <div className={styles.typeRow}>
        <p>Book type:</p>
        <h4>{bookType==='journal' ? 'Journal' : summaryDetails.type}{summaryDetails.type==='standard'?` x${summaryDetails.copies}`:null}</h4>
      </div>
        {
          summaryDetails.type!=='standard' &&
          <>
            <div className={styles.titleRow}>
              <h4>Personalized copies by:</h4>
              <h4>Quantity</h4>
            </div>
            {   
              summaryDetails.items.map((i, idx)=>{
                return(
                  <div className={styles.row}>
                    <p>{i.studentName}</p>
                    <p>{i.quantity}</p>
                  </div>
                )
              })
            }
          </>
        }
  </div>
  )

}

export default OrderDetailBlock;