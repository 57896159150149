import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import ls from 'local-storage'
import { postImpersonateStudent } from '../../../API/Auth.service';


const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const ImpersonateStudent = () => {
  const location = useLocation()
  const history = useHistory()
  const parsed = queryString.parse(location.search);
  const [sid] = useState(parsed.sid);
  const [serverErrors, setServerErrors] = useState();
  const token = ls('t')

  useEffect(()=>{
    impersonateStudent()
  })

  async function impersonateStudent(){
    if(!token){
      history.push('/auth/student')
    }else{
      let errors;
      if(sid){
        errors = await postImpersonateStudent({t:token,sid:sid})
      }else{
        errors = await postImpersonateStudent({t:token})
      }
      
      errors && setServerErrors(errors)
    }
  }

  return ( 
    <div style={styles}>
      Loading ...
      <br/>
      {serverErrors&& serverErrors}
    </div>
   );
}
 
export default ImpersonateStudent;