import React from 'react';
import styles from './ParishesAndInstitutions.module.scss';
import fjLogo from 'Assets/logos/faith_logo_classes.svg';
import fcLogo from 'Assets/logos/faith_logo_courses.svg';
import churchImg from 'Assets/illustrations/church_coloured.png'
import usersCycle from 'Assets/illustrations/usersCycle.svg';
import usersScreen from 'Assets/illustrations/usersScreen.svg';
import bookends from 'Assets/illustrations/bookends3.svg';
import { Link } from 'react-router-dom';

const features = [
  {
    img: usersCycle,
    text:"Connected Network of Instructors"
  },
  {
    img: usersScreen,
    text:"Interactive Online instructor-guided programs"
  },
  {
    img: bookends,
    text:"Unified private content library"
  }
]

const ParishesAndInstitutions = () => {

  return (
    <div className={styles.container}>
      <section className={`${styles.hero}`}>
        <div className={`${styles.inner} ${styles.row}`}>
          <img className={`${styles.churchImg}`} src={churchImg} alt="school illustration" />
          <div className={`${styles.text}`}>
            <h4>Parishes and other institutions</h4>
            <h3>Making disciples of all</h3>
            <p>Faith Journals allows you to create your own active-learning courses and share them with different instructors to create a unified program.</p>
            <p>This platform combines a simple user interface with the opportunity to create reflection activities that enable instructors and participants to interact and collaborate in real-time.</p>
            <p>Faith Journals provides a complete and simple solution for institutions to adapt their Faith Formation programs to a new technology-oriented environment.</p>
          </div>
        </div>
      </section>

      {/* <section>
      </section> */}

      <section className={`${styles.blueSection} ${styles.noPadd}`}>
        <div className={`${styles.inner}`}>
          <div className={`${styles.cards}`}>
            {
              features.map((i,idx) => 
                <div key={idx} className={`${styles.card}`}>
                  <img src={i.img} alt="card image" />
                  <h5>{i.text}</h5>
                </div>
              )
            }
          </div>
          <div className={`${styles.learnMore}`}>
            <h3>Do you want to learn more about Faith Journals tools?</h3>
            <div className={`${styles.tools}`}>
              <div className={`${styles.toolCard}`}>
                <h6 className={`${styles.green}`}>3rd-8th Grade</h6>
                <img src={fjLogo} alt="Faith Journals Clasrooms logo" />
                <p>Faith Journals Classrooms is a collaborative writing software that uses a creative pedagogy that has been proven to inspire students to write about Sacred Scripture and grow closer to God, while simultaneously enhancing their writing proficiency</p>
                <Link to={`/?tab=journals`} className={`${styles.learnMoreBtn}`}>Learn more</Link>
              </div>
              <div className={`${styles.toolCard}`}>
                <h6 className={`${styles.orange}`}>Young adults</h6>
                <img src={fcLogo} alt="Faith Journals Courses logo" />
                <p>Faith Journals Courses provides a complete and simple solution to faith formation allowing private content delivery and interaction between instructors and group participants</p>
                <Link to={`/?tab=academy`} className={`${styles.learnMoreBtn}`}>Learn more</Link>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )

}

export default ParishesAndInstitutions;