import React from 'react';

import styles from './ConfirmationModal.module.scss';
import tick from '../../../Assets/Icons/icon-circleTick.svg'

const ConfirmationModal = ({ heading, text, closeAction}) => {

  return (
    <div className={styles.container}>
      <img src={tick} alt="tick" />
      <h2>{heading}</h2>
      <p>{text}</p>

      <div onClick={()=>closeAction(true)} className={styles.closeBtn}>Close</div>
    </div>
  )

}

export default ConfirmationModal;