import React from 'react';
import schoolImg from 'Assets/illustrations/school_coloured3.png'
import styles from './Schools.module.scss';
import TestimonialsCarousel from 'Components/Homepage/Sections/TestimonialsCarousel/TestimonialsCarousel';
import puzzle from 'Assets/illustrations/puzzle2.svg';
import laptop_chat from 'Assets/illustrations/laptop_chat.svg';
import laptop_arrows from 'Assets/illustrations/laptop_arrows.svg';
import bookends from 'Assets/illustrations/bookends2.svg';
import hand_heart from 'Assets/illustrations/hand_heart.svg';
import support from 'Assets/illustrations/support.svg';
import logo from 'Assets/logos/faith_logo_text.svg';
import Pricing from 'Components/Pricing/Pricing';
import Collaboration from 'Components/Homepage/Sections/Collaboration/Collaboration';

const cards = [
  {
    img: puzzle,
    text:<p><strong>Easy </strong> to use, facilitates teacher class management</p>
  },
  {
    img: laptop_chat,
    text:<p>Technology perfect for <strong>in-classroom, outside of the classroom or hybrid</strong> use</p>
  },
  {
    img: support,
    text:<p>Personalised <strong>teacher training</strong> program and <strong>ongoing support</strong></p>
  },
  {
    img: bookends,
    text:<p>Allows you to create common <strong>resources</strong> to use <strong>accross multiple classrooms/schools</strong></p>
  },
  {
    img: laptop_arrows,
    text:<p>Supports the reinforcement of <strong>faith identity</strong> helping students deepen their faith</p>
  },
  {
    img: hand_heart,
    text:<p>Showcases <strong>schools' investment in faith education</strong> allowing <strong>fundraising opportunities</strong> with Journal publishing</p>
  },
]

const Schools = () => {

  return (
    <div className={styles.container}>
      <section className={`${styles.blueSection}`}>
        <div className={`${styles.inner} ${styles.row} ${styles.hero}`}>
          <img className={`${styles.school}`} src={schoolImg} alt="school illustration" />
          <div className={`${styles.text}`}>
            <h4>Schools</h4>
            <h3>Inspiring students to be disciples</h3>
            <p>Faith Journals provides the tools to create activities for reading, writing and reflecting on the Word of God or any other material used in class, supporting and transforming Religious Education</p>
          </div>
        </div>
      </section>

      <Collaboration />
      
      <TestimonialsCarousel noPadd />

      <section>
        <div className={`${styles.inner} ${styles.cards} ${styles.row}`}>
          {
            cards.map((i,idx) => 
              <div className={`${styles.card}`}>
                <div className={`${styles.imgContainer}`}>
                  <img src={i.img} alt="card img" />
                </div>
                {i.text}
              </div>
            )
          }
        </div>
      </section>

      <section className={`${styles.blueSection} ${styles.noPadd}`}>
        <div className={`${styles.inner} ${styles.learnMore}`}>
          <img src={logo} alt="logo" />
          <h3>Do you want to learn more about Faith Journals?</h3>
          <a href="mailto:info@faith-journals.com" className={`${styles.btn}`}>Contact us</a>
        </div>
      </section>

    </div>
  )

}

export default Schools;