import React from 'react'
import styles from './Collaboration.module.scss'

import fj from './fj.svg'
import sophia from './sophia.svg'
import shake from './handshake.svg'
import book from './book.svg'
import rosa from './rosa.svg'
import icon1 from './Capa1.svg'
import icon2 from './Capa6.svg'
import icon3 from './Capa2.svg'



import flower from './flowers.svg'

const Collaboration = () => {
  return (
    <div className={styles.collaboration}>
        <div className={styles.header}>
            <div>
                <img src={sophia} alt="fj" />
            </div>


            <div className={styles.shakes}>
                <h4>
                    is now <br />
                    <strong>
                        partnering with  
                    </strong>
                </h4>
                <img src={shake} alt="shake" />
            </div>

            <div>
                <img src={fj} alt="sophia" />
            </div>
        </div>

        <div className={styles.center}>
            <h6>
                <strong>
                    The easiest and Most Engaging Online Tool <br />
                    </strong>
                now offers the <br />
                 <strong>
                 Most Comprehensive High School Theology Program
                    </strong>
            </h6>
        </div>


        <div className={styles.body}>
            <div className={styles.image}>
                <img src={book} alt="book" />
                <div className={styles.rosa}>
                    <img src={rosa} alt="rosary" />
                </div>
            </div>
            
            <div className={styles.main}>
                <p>
                    <strong> Sophia Institute for Teachers  </strong>has now integrated their <strong>Spirit of Truth High School </strong>
                    Student Workbooks into
                     the Faith Journals platform to provide teachers with <strong>ready-made reflection activities and writing prompts</strong> to
                      engage students in an online environment.
                </p>
                <br />
                <p>
                    <em>Spirit of truth...</em>
                </p>
                <ul>

                    <li>
                     Spirit of Truth...
                    </li>
                    <li>
                     Offers time-saving, fully developed lesson plans
                    </li>
                    <li>
                     Includes a wide range of activities, including reading comprehension, reflective exercises, Sacred Art discussions, and prayers
                    </li>
                    <li>
                     Helps your students internalize the Faith and apply it to their own lives
                    </li>
                    <li>
                    Follows the USCCB doctrinal framework!
                    </li>

                </ul>

                <div className={styles.right}>
                    <a href="https://sophiainstituteforteachers.org/shop/spirit-of-truth/high-school-edition" target="_blank" rel="noopener noreferrer">
                        Learn More About Spirit of Truth</a>
                </div>
            </div>
        </div>
        <br />
        <div>
        
            <div className={styles.left}>

            <div className={styles.list}>
                <p className={styles.indent}>
                    <strong>With Faith Journals tools...</strong>
                </p>
                <div className={styles.item}>
                    <div>
                        <img src={icon1} alt="icon" />
                    </div>
                    <div>
                        <p>Teachers can see student&apos;s work and progress in real time</p>
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src={icon2} alt="icon" />
                    </div>
                    <div>
                        <p>Teachers can provide individual feedback</p>
                    </div>
                </div>
                <div className={styles.item}>
                    <div>
                        <img src={icon3} alt="icon" />
                    </div>
                    <div>
                        <p>Students can collaborate</p>
                    </div>
                </div>
            </div>
            </div>
            <div className={styles.center}>
                <a href="mailto:info@faith-journals.com">contact us</a>
            </div>

            <div className={styles.flowers}>
                <img src={flower} alt="flowera" />
            </div>
        </div>

    </div>
  )
}

export default Collaboration