import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import queryString from "query-string";

import Auth from "Components/Auth/Auth";
import ConfirmEmail from "Components/Auth/ConfirmEmail/ConfirmEmail";
import Impersonate from "Components/Auth/Impersonate/Impersonate";
import Logout from "Components/Auth/Logouts/Logout";
import ResetPassword from "Components/Auth/ResetPassword/ResetPassword";
import Homepage from "./Components/Homepage/Homepage";
import Footer from "./Components/Structural/Footer/Footer";
import ContactPopper from "Components/SchoolsAndParishes/ContactPopper";

import "./Styles/shared.scss";
import LoginModal from "Components/Auth/LoginModal/LoginModal";
import LinkLogin from "Components/Auth/LinkLogin/LinkLogin";
import StudentLogout from "Components/Auth/Logouts/StudentLogout";
import Navbar from "Components/Structural/Navbar/Navbar";
import ImpersonateStudent from "Components/Auth/Impersonate/ImpersonateStudent";
import Terms from "Components/Terms/Terms";
import PrivacyPolicy from "Components/PrivacyPolicy/PrivacyPolicy";
import CookiePolicy from "Components/CookiePolicy/CookiePolicy";
import AboutUs from "Components/AboutUs/AboutUs";
import Pricing from "Components/Pricing/Pricing";
import Bookstore from "Components/Bookstore/Bookstore";
import { useState } from "react";
import Schools from "Components/Schools/Schools";
import ParishesAndInstitutions from "Components/ParishesAndInstitutions/ParishesAndInstitutions";

function App() {
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [loginModalState, toggleLoginModal] = useState(false);
  const htmlRoot = document.getElementById("wrapper");

  useEffect(() => {
    //hide login modal when user navigates without closing
    toggleLoginModal(false);
    // reset scrollTop to zero upon route change (unless auto-scrolling to specific seciton)
    // tab query param will cancel this reset in order to auto-scroll to carousel section
    if (htmlRoot && !parsed.tab) htmlRoot.scrollTop = 0;
  }, [location]);

  return (
    <div className="App">
      <Switch>
        {" "}
        {/* Nav switch */}
        <Route
          exact
          path="/"
          component={() => <Navbar setShowLogin={toggleLoginModal} />}
        />
        <Route path="/auth/:section" component={null} />
        <Route path="/donate/:schoolName/payment" component={null} />
        <Route
          path="/:section"
          component={() => <Navbar setShowLogin={toggleLoginModal} />}
        />
      </Switch>

      <Switch>
        {/* main routes */}
        <Route
          exact
          path="/"
          component={() => <Homepage autoScrollTab={parsed.tab} />}
        />
        <Route
          exact
          path="/faithcademy"
          component={() => <Homepage academyTabSelected />}
        />
        <Route exact path="/schools" component={() => <Schools />} />
        <Route
          exact
          path="/parishes+institutions"
          component={() => <ParishesAndInstitutions />}
        />
        <Route
          exact
          path="/pricing"
          component={() => (
            <Pricing showHeader setShowLogin={toggleLoginModal} />
          )}
        />
        <Route exact path="/about" component={() => <AboutUs />} />
        <Route exact path="/store/:section?" component={() => <Bookstore />} />

        {/* legal stuff */}
        <Route path="/home/terms" component={() => <Terms />} />
        <Route
          path="/home/privacy-policy"
          component={() => <PrivacyPolicy />}
        />
        <Route path="/home/cookie-policy" component={() => <CookiePolicy />} />

        {/* auth routes */}
        <Route path="/auth/forgotPassword" component={() => <Auth forgot />} />
        <Route path="/auth/confirm_email" component={ConfirmEmail} />
        <Route path="/auth/reset_password" component={ResetPassword} />
        <Route path="/auth/logout" component={Logout} />
        <Route path="/auth/student/logout" component={StudentLogout} />
        <Route
          path="/auth/student/setPassword"
          component={() => <Auth setPassword />}
        />
        <Route path="/auth/student/go" component={LinkLogin} />
        <Route path="/auth/student/login_as" component={ImpersonateStudent} />
        <Route path="/auth/teacher/login_as" component={Impersonate} />
        <Route path="/auth/:account?/:authType?" component={Auth} />
      </Switch>

      <Switch>
        {" "}
        {/* footer switch */}
        <Route exact path="/" component={() => <Footer />} />
        <Route path="/auth" component={null} />
        <Route path="/:section" component={() => <Footer />} />
      </Switch>

      <ContactPopper />

      <LoginModal
        setModal={toggleLoginModal}
        loginModalState={loginModalState}
      />
    </div>
  );
}

export default App;
