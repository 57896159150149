import React, { useState } from 'react';
import './Switch.scss';


const SwitchButton = ({selectedValue, handleChange}) => {

    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
      setIsActive(!isActive);
      handleChange();
    };
  return (
    <>
        <div className={`toggle-button ${isActive ? 'active' : ''}`} onClick={handleClick}>
      <div className="rolling-ball" />

      {
        isActive ? 
        <span className="label-left">
            Annual
        </span>
        :
        <span className='label-right'>
            Monthly
        </span>
      }
     
    </div>
    </>
  );
};

export default SwitchButton;
