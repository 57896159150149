// Pricing actions
export const setPricingTerm = (term) => {
  return{
    type:'SET_PRICING_TERM',
    payload:term
  }
}
export const setMonths = (months) => {
  return{
    type:'SET_MONTHS',
    payload:months
  }
}
export const setPlanTerm = (plan) => {
  return{
    type:'SET_PLAN',
    payload:plan
  }
}

export const setPrice = (price) => {
  return{
    type:'SET_PRICE',
    payload:price
  }
}

export const setStageRedux = (stage) => {
  return{
    type:'SET_STAGE',
    payload:stage
  }
}

export const setBookDetailsRedux = (object) => {
  return{
    type:'SET_BOOK_DETAILS',
    payload:object
  }
}

export const setSummaryDetailsRedux = (object) => {
  return{
    type:'SET_SUMMARY_DETAILS',
    payload:object
  }
}

export const removeChild = (studentId) => {
  console.log(studentId)
  return{
    type:'REMOVE_CHILD',
    studentId:studentId
  }
}

export const setUserDetailsRedux = (object) => {
  return{
    type:'SET_USER_DETAILS',
    payload:object
  }
}