import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string'
import ls from 'local-storage'
import { postImpersonate } from '../../../API/Auth.service';

const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const Impersonate = () => {
  const location = useLocation()
  const history = useHistory()
  const parsed = queryString.parse(location.search);
  const [tid] = useState(parsed.tid);
  const [serverErrors, setServerErrors] = useState();
  const token = ls('t')

  useEffect(()=>{
    impersonate()
  })

  async function impersonate(){
    if(!token){
      history.push('/auth/teacher')
    }else{
      let errors = await postImpersonate({t:token,tid:tid})
      errors && setServerErrors(errors)
    }
  }

  return ( 
    <div style={styles}>
      Loading ...
      <br/>
      {serverErrors&& serverErrors}
    </div>
   );
}
 
export default Impersonate;