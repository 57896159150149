import axios from 'axios';
import ls from 'local-storage'
const session = window.sessionStorage
const host = window.location.host 
const env = 
  host === 'localhost:3011' ? 'dev' : 
  host === 'sand.faith-journals.com' ? 'stage' : 'prod';

const baseURL = env === 'prod' ? 
`https://backapi.faith-journals.com/api` :
`https://backapi.sand.faith-journals.com/api`

const signUrl = () => `${baseURL}/auth/signup`

  
const logUrl = () => `${baseURL}/auth/login`


const signUrlStudent = () => `${baseURL}/auth/student_signup`

  
const logUrlStudent = () => `${baseURL}/auth/student_login`

  
const logUrlStudentLink = () => `${baseURL}/auth/student_go`

  
const setPasswordUrl = () => 
  env ==='dev' ?
    `http://${host}/auth/student/setPassword` :
    `https://${host}/auth/student/setPassword`


const studentGoogleAPIUrl = () => 
   `${baseURL}/auth/student_google_login`


const passwordStudentAPIUrl = () => 
  `${baseURL}/auth/student_set_password`


const forgotAPIUrl = (isStudent) => 
   `${baseURL}/auth/forgot${isStudent?`?student=true` : ''}`


const confirmEmailAPIUrl = () => 
  `${baseURL}/auth/confirm_email`


const resetPasswordUrl = () => 
  `${baseURL}/auth/teacher_set_password`


const impersonateAPIUrl = () => 
  `${baseURL}/auth/login_as`


const impersonateStudentAPIUrl = () => 
  `${baseURL}/auth/login_as_student`



export function postAuthLandingForm(newData) {

  return axios.post(signUrl(env), newData)
    .then(response => {
      return response.data.errors ? JSON.parse(response.data.errors) : window.location.replace(response.data.url)
    })
    .catch((error) => {
      console.log(error)
    })

}

export async function postSignUp(newData) {
  let r = session.getItem('r');
  let rc = session.getItem('rc');
  if(r)newData.r=r
  if(rc)newData.rc=rc
  console.log(newData)
  try {
    let response = await axios.post(signUrl(env), newData)
    response.data.t && ls('t', response.data.t)
    
    if(response.data.errors) return JSON.parse(response.data.errors)
    setTimeout(()=>{ window.location.replace(response.data.url) },3500)

  } catch (error) {
    console.log(error)
  }
}

export async function postLogin(newData) {
  try {
    let response = await axios.post(logUrl(env), newData)
    response.data.t && ls('t', response.data.t)
    response.data.t && ls('t', response.data.t)


    let redirectUrl = response.data.url
    // update redirect URL to work in development
    if(env === 'dev' && redirectUrl && redirectUrl.includes("app.sand.")) {
      redirectUrl = redirectUrl.replace("https://app.sand.faith-journals.com", "http://localhost:3000")
    }

    return response.data.errors ? response.data.errors : window.location.replace(redirectUrl)
  } catch (error) {
    console.log(error)
  }

}

export async function postLoginStudent(newData) {
  try {
    let response = await axios.post(logUrlStudent(env), newData)
    console.log(response)
    ls('loginResponse', response)
    if (response.data.set_password) {
      response.data.st && ls('set', response.data.st)
      
      window.location.replace(setPasswordUrl())
    } else {
      response.data.st && ls('st', response.data.st)
  
      return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
    }
  } catch (error) {
    console.log(error)
  }

}

export async function postSignUpStudent(newData) {
  try {
    let response = await axios.post(signUrlStudent(env), newData)
    console.log(response)
    response.data.st && ls('st', response.data.st)
    
    if(response.data.errors) return response.data.errors
    setTimeout(()=>{ window.location.replace(response.data.url) },3500)

  } catch (error) {
    console.log(error)
  }
}

export async function postLoginLink(data) {
  try {
    let response = await axios.post(logUrlStudentLink(env), data)
    console.log(response)
    if (response.data.set_password) {
      response.data.st && ls('set', response.data.st)
      window.location.replace(setPasswordUrl())
    } else {
      response.data.st && ls('st', response.data.st)
      return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
    }
  } catch (error) {
    console.log(error)
  }

}

export async function postGoogleLoginStudent(tokenObj) {
  try {
    let response = await axios.post(studentGoogleAPIUrl(env), tokenObj)
    response.data.st && ls('st', response.data.st)

    return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
  } catch (error) {
    console.log(error)
  }

}

export async function postPasswordStudent(newData, token) {
  try {
    let response = await axios.post(passwordStudentAPIUrl(env), newData, { headers: { 'Authorization': token || "" } })
    token && ls('st',token)
    ls.remove('set')
    return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
  } catch (error) {
    console.log(error)
  }
}

export async function postForgot(newData, isStudent) {

  try {
    let response = await axios.post(forgotAPIUrl(isStudent), newData)
    return response.data.errors ? response.data.errors : true
  } catch (error) {
    console.log(error)
  }
}

export async function postConfirmEmail(newData) {
  try {
    let response = await axios.post(confirmEmailAPIUrl(env), newData)
    response.data.t && ls('t', response.data.t)

    return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
  } catch (error) {
    console.log(error)
  }
}

export async function postResetPassword(newData) {
  try {
    console.log(resetPasswordUrl(env), newData)
    let response = await axios.post(resetPasswordUrl(env), newData)
    console.log(response)
    response.data.t && ls('t', response.data.t)

    return response.data.errors ? response.data.errors : window.location.replace(response.data.url)
  } catch (error) {
    console.log(error)
  }
}

export async function postImpersonate(newData) {
  console.log('post impersonate')
  try {
    let response = await axios.post(impersonateAPIUrl(env), newData)
    response.data.t && ls('t', response.data.t)

    response.data.at && ls('at', response.data.at)

    let impersonateUrl = response.data.url
    if(window.location.host === "localhost:3011") {
      impersonateUrl = impersonateUrl.replace("https://app.sand.faith-journals.com", "http://localhost:3000")
    }
    return response.data.errors ? response.data.errors : window.location.replace(impersonateUrl)
  } catch (error) {
    console.log(error)
  }
}

export async function postImpersonateStudent(newData) {
  console.log('post imp')
  try {
    let response = await axios.post(impersonateStudentAPIUrl(env), newData)
    response.data.st && ls('st', response.data.st)

    let miniMeUrl = response.data.url
    if(window.location.host === "localhost:3011") {
      miniMeUrl = miniMeUrl.replace("https://write.sand.faith-journals.com", "http://localhost:3010")
    }
    return response.data.errors ? response.data.errors : window.location.replace(miniMeUrl)
  } catch (error) {
    console.log(error)
  }
}

