import React, { useEffect, useState } from 'react';

import styles from './SetPassword.module.scss'
import showPasswordFalse from './../../../Assets/Icons/visibility-black-18dp.svg'
import showPasswordTrue from './../../../Assets/Icons/visibility_off-black-18dp.svg'
import { useHistory } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { postPasswordStudent } from '../../../API/Auth.service';
import ls from 'local-storage';

import forgotten from 'Assets/auth/forgotten-password.png'

const SetPassword = () => {

  // const [isSignup, setSignUp] = useState(false);
  const { register, handleSubmit, getValues, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState();
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [error, setError] = useState(false);
  const [serverErrors, setServerErrors] = useState(undefined);
  const history = useHistory()


  async function onSubmit(data) {
    let errors = await postPasswordStudent(data, ls('set'))
    errors && setServerErrors(errors)
    

  }

  useEffect(() => {
    const set = ls('set')
    set ? setToken(set) : history.push('/auth/student')
  },[history]) 


  return (
    <div className={styles.setPassword}>
      <div>

        <figure>
          <img src={forgotten} alt=''/>
        </figure>

        <header className={styles.header}>
          <h2>Set a new password</h2>
        </header>
        <main>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            <div className={styles.registerForm}>

              <div className={styles.inputDiv}>
                <label htmlFor="password">Password</label>
                <div className={errors.password ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
                  <input className={styles.input} type={showPassword ? 'text' : 'password'} name='password' placeholder="Password" ref={register({ required: 'Password is required', minLength: { value: 6, message: 'Min password length is 6 ' } })} />
                  <img src={showPassword ? showPasswordTrue : showPasswordFalse} alt='show password off' onClick={() => setShowPassword(!showPassword)} />
                </div>
                <p className={styles.errorMessage}>{errors.password && errors.password.message}</p>
              </div>


              <div className={styles.inputDiv}>
                <label htmlFor="confirmPassword">Password confirmation</label>
                <div className={errors.confirmPassword ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
                  <input className={styles.input} type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' placeholder="Password" ref={register({ validate: value => value === getValues('password') || "Passwords must match" })} />
                  <img src={showConfirmPassword ? showPasswordTrue : showPasswordFalse} alt='show password on off' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                </div>
                <p className={styles.errorMessage}>{errors.confirmPassword && errors.confirmPassword.message}{serverErrors && serverErrors}</p>
              </div>
            </div>

            <input className={styles.submit} type="submit" value="Save" />


          </form>
        </main>
      </div>
    </div>
  );
}

export default SetPassword;