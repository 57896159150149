import loli from 'Assets/TeamPhotos/loli.png';
import ken from 'Assets/TeamPhotos/ken.png';
import sergiu from 'Assets/TeamPhotos/sergiu.png';
import paschal from 'Assets/TeamPhotos/ezepaschal.jpg';

export const teamMembers =  [
  {
    name:"Loli Lopez-Montenegro",
    title: " Co-founder & CEO",
    image:loli
  },
  {
    name:"Ken Haynes",
    title: "Co-founder",
    image:ken
  },
  {
    name:"Sergiu Truta",
    title: " Co-founder & CTO",
    image:sergiu
  },
  {
    name:"Eze Chidera Paschal",
    title: "Frontend Developer",
    image:paschal
  }
]