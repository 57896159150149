import React from 'react';

import styles from './checkoutStyles.module.scss';

const Steps = ({ stage=1 }) => {

  return (
    <div className={styles.steps}>

      <div className={styles.inner}>
        <div className={styles.step}>
          <div className={`${styles.bubble} ${stage===1?styles.blue:styles.green}`}><p> 1 </p></div>
          <h6>Order details</h6>
        </div>

        <div className={styles.step}>
          <div className={`${styles.bubble} ${stage===1?styles.grey:stage===2?styles.blue:styles.green}`}><p> 2 </p></div>
          <h6>Shipping</h6>
        </div>

        <div className={styles.step}>
          <div className={`${styles.bubble} ${stage===3?styles.blue:stage===4?styles.green:styles.grey}`}><p> 3 </p></div> <h6>Payment</h6>
        </div>

        <div className={styles.line}></div>
        
      </div>

    </div>
  )

}

export default Steps;