import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useSwipeable } from 'react-swipeable'
import { debounce_deffered } from '../../../../Helpers/debounce_deffered';
import slide1 from '../../../../Assets/homepage/slide1.png';
import slide2 from '../../../../Assets/homepage/slide2.png';
import slide3 from '../../../../Assets/homepage/slide3.png';
import slide4 from '../../../../Assets/homepage/slide4.png';
import teacherView from '../../../../Assets/illustrations/teacherView.svg';
import studentView from '../../../../Assets/illustrations/studentView.svg';
import arrow from '../../../../Assets/Icons/arrow_circle_red.png';

import styles from './HowItWorks.module.scss';

const HowItWorks = ({ dispatch, isVisible }) => {

  const [ selectedIdx, setSelectedIdx ] = useState(1);
  const [ cardsViewed, toggleViewed ] = useState(false);
  const contRef = useRef();

  const slideText = idx => 
    idx == 1 ?
      {title:"Reflection Questions", body:"Teachers set up the reading material and reflection questions, organized in a multiple-day activity or sections."}
  : idx == 2 ?
      {title:"Student Writing", body:"Students reflect and write in response to the teacher prompt."}
  : idx == 3 ?
      {title:"Teacher Guidance", body:"Teachers supervise each student's work and provide guidance in real-time."}
  : idx == 4 &&
      {title:"Avatar Store", body:"Students earn points as they write that can be used to dress up their avatars!"}

  useEffect(()=>{
    if(selectedIdx===3)toggleViewed(true)
  },[selectedIdx])

  const handlers = useSwipeable({
    onSwipedLeft: () => selectedIdx<3 && setSelectedIdx(selectedIdx+1),
    onSwipedRight: () => selectedIdx>1 && setSelectedIdx(selectedIdx-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const toggleModal = payload => dispatch({ type: "TOGGLE_MODAL", payload })

  return !isVisible ? null : (
    <div ref={contRef} 
      // onWheel={event=>wheelEvent(event, selectedIdx, cardsViewed)} 
      className={`${styles.howItWorksContainer} ${styles.sectionContainer}`}>
      <div {...handlers} className={styles.inner}>

        <div className={styles.infoDiv}>
          <h1 className={styles.mainTitle}>How it works</h1>
          <div className={styles.radios}>
            <div onClick={() => setSelectedIdx(1)} className={`${styles.radio} ${selectedIdx === 1 && styles.selected}`}> 1 </div>
            <div onClick={() => setSelectedIdx(2)} className={`${styles.radio} ${selectedIdx === 2 && styles.selected}`}> 2 </div>
            <div onClick={() => setSelectedIdx(3)} className={`${styles.radio} ${selectedIdx === 3 && styles.selected}`}> 3 </div>
            <div onClick={() => setSelectedIdx(4)} className={`${styles.radio} ${selectedIdx === 4 && styles.selected}`}> 4 </div>
          </div>
          <div className={styles.text}>
            <h4>{selectedIdx}. {slideText(selectedIdx).title}</h4>
            <p>{slideText(selectedIdx).body}</p>
          </div>
          <div className={`${styles.btns}`}>
            <div onClick={()=>toggleModal([2,4].indexOf(selectedIdx) == -1 ? 'teacher' : 'student')} className={styles.onboardingBtn}>
              Download instructions PDF
              <div className={styles.arrow} />
            </div>
            <a href={"mailto:info@faith-journals.com"} className={`${styles.contactBtn}`}>
              Speak with us
            </a>
          </div>
        </div>

        <div className={styles.slides}>
          <h1 className={styles.resTitle}>How it works</h1>
          <div className={styles.views}>
            <img className={[2, 4].indexOf(selectedIdx) == -1 && `${styles.active} ${styles.bounceSm}`} src={teacherView} alt="teacher view" />
            <img className={[2, 4].indexOf(selectedIdx) != -1 && `${styles.active} ${styles.bounceSm}`} src={studentView} alt="student view" />
          </div>
          <div className={`${styles.slide} ${selectedIdx === 1 && styles.selected}`}>
            <img src={slide1} alt="slide 1" />
          </div>
          <div className={`${styles.slide} ${styles.two} ${selectedIdx === 2 && styles.selected}`}>
            <img src={slide2} alt="slide 2" />
          </div>
          <div className={`${styles.slide} ${selectedIdx === 3 && styles.selected}`}>
            <img src={slide3} alt="slide 3" />
          </div>
          <div className={`${styles.slide} ${styles.four} ${selectedIdx === 4 && styles.selected}`}>
            <img src={slide4} alt="slide 4" />
          </div>
          <div className={styles.dots}>
            <div onClick={() => setSelectedIdx(1)} className={`${styles.dot} ${selectedIdx === 1 && styles.active}`} />
            <div onClick={() => setSelectedIdx(2)} className={`${styles.dot} ${selectedIdx === 2 && styles.active}`} />
            <div onClick={() => setSelectedIdx(3)} className={`${styles.dot} ${selectedIdx === 3 && styles.active}`} />
            <div onClick={() => setSelectedIdx(4)} className={`${styles.dot} ${selectedIdx === 4 && styles.active}`} />
          </div>
        </div>
      </div>
    </div>
  )

}

export default HowItWorks;





// <div 
// // onWheel={event=>wheelEvent(event, selectedIdx)} 
// className={styles.radios}>
// <div className={`${styles.radiosInner} ${innerClass(selectedIdx)}`}>
//   <div onClick={()=>setSelectedIdx(1)} className={`${styles.radio} ${selectedIdx === 1 && styles.selected}`}>
//     <h3 className={styles.number}>1</h3>
//     <h4>Reflection Questions</h4>
//     <p className={styles.radioBody}>Teachers set up the reading material and reflection questions, organized in a multiple-day activity or sections.</p>
//   </div>
//   <div onClick={()=>setSelectedIdx(2)} className={`${styles.radio} ${selectedIdx === 2 && styles.selected}`}>
//     <h3 className={styles.number}>2</h3>
//     <h4>Student Writing</h4>
//     <p className={styles.radioBody}>Students reflect and write in response to the teacher prompt.</p>
//   </div>
//   <div onClick={()=>setSelectedIdx(3)} className={`${styles.radio} ${selectedIdx === 3 && styles.selected}`}>
//     <h3 className={styles.number}>3</h3>
//     <h4>Teacher Guidance</h4>
//     <p className={styles.radioBody}>Teachers supervise each student's work and provide guidance in real-time.</p>
//   </div>
//   <div onClick={()=>setSelectedIdx(4)} className={`${styles.radio} ${selectedIdx === 4 && styles.selected}`}>
//     <h3 className={styles.number}>4</h3>
//     <h4>Avatar Store</h4>
//     <p className={styles.radioBody}>Students earn points as they write that can be used to dress up their avatars!</p>
//   </div>
// </div>

// <div className={styles.arrows}>
//   <img onClick={()=>{if(selectedIdx>1)setSelectedIdx(selectedIdx-1)}} className={`${styles.leftArrow} ${selectedIdx==1 && styles.inactive}`} src={arrow} alt="left arrow" />
//   <img onClick={()=>{if(selectedIdx<4)setSelectedIdx(selectedIdx+1)}} className={`${selectedIdx==4 && styles.inactive}`} src={arrow} alt="right arrow" />
// </div>
// </div>