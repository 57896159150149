import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import  queryString  from 'query-string' 

import styles from './LinkLogin.module.scss';
import { postLoginLink } from '../../../API/Auth.service';

const LinkLogin = () => {
  
  const [ error, setError ] = useState(null)
  const location = useLocation()
  const history = useHistory()
  const { e, c } = queryString.parse(location.search)
  const callApi = async (e, c) => { 
    setError(await postLoginLink({email:e, code:c}))
  }

  useEffect(()=>{
    callApi(e, c)
  },[])

  useEffect(()=>{
    if(error){
      console.log(error)
      setTimeout(()=>{ history.push('/auth/student') },1500)
    }
  },[error])


  return (
    <div className={styles.container}>
      {
        error ? 
          <>
            <h5>{`${error} :(`}</h5>
            <h5>You are being redirected to the login service...</h5>
          </>

        :
          <h5>Redirecting</h5>

      }
    </div>
  )

}

export default LinkLogin;