import googleLight from 'Assets/homepage/featureIcons/google_light.svg';
import googleDark from 'Assets/homepage/featureIcons/google_dark.svg';
import chatLight from 'Assets/homepage/featureIcons/chat_light.svg';
import chatDark from 'Assets/homepage/featureIcons/chat_dark.svg';
import collabLight from 'Assets/homepage/featureIcons/collab_light.svg';
import collabDark from 'Assets/homepage/featureIcons/collab_dark.svg';
import roomsLight from 'Assets/homepage/featureIcons/rooms_light.svg';
import roomsDark from 'Assets/homepage/featureIcons/rooms_dark.svg';
import imageLight from 'Assets/homepage/featureIcons/image_light.svg';
import imageDark from 'Assets/homepage/featureIcons/image_dark.svg';
import duplicateLight from 'Assets/homepage/featureIcons/duplicate_light.svg';
import duplicateDark from 'Assets/homepage/featureIcons/duplicate_dark.svg';
import unlimitedLight from 'Assets/homepage/featureIcons/unlimited_light.svg';
import unlimitedDark from 'Assets/homepage/featureIcons/unlimited_dark.svg';
import vocabLight from 'Assets/homepage/featureIcons/vocab_light.svg';
import vocabDark from 'Assets/homepage/featureIcons/vocab_dark.svg';
import pdfLight from 'Assets/homepage/featureIcons/pdf_light.svg';
import pdfDark from 'Assets/homepage/featureIcons/pdf_dark.svg';
import rubricsLight from 'Assets/homepage/featureIcons/rubrics_light.svg';
import rubricsDark from 'Assets/homepage/featureIcons/rubrics_dark.svg';
import developmentLight from 'Assets/homepage/featureIcons/development_light.svg';
import developmentDark from 'Assets/homepage/featureIcons/development_dark.svg';
import libraryLight from 'Assets/homepage/featureIcons/library_light.svg';
import libraryDark from 'Assets/homepage/featureIcons/library_dark.svg';
import videoLight from 'Assets/homepage/featureIcons/video_light.svg';
import videoDark from 'Assets/homepage/featureIcons/video_dark.svg';


export const featureData = [
  {
    hoverIcon:googleLight,
    icon:googleDark,
    title:"Google Integration"
  },
  {
    hoverIcon:chatLight,
    icon:chatDark,
    title:"In-app chat"
  },
  {
    hoverIcon:collabLight,
    icon:collabDark,
    title:"Teacher Collaboration"
  },
  {
    hoverIcon:roomsLight,
    icon:roomsDark,
    title:"Breakout writing rooms"
  },
  {
    hoverIcon:imageLight,
    icon:imageDark,
    title:"Image uploads"
  },
  {
    hoverIcon:duplicateLight,
    icon:duplicateDark,
    title:"Duplicate activities"
  },
  {
    hoverIcon:unlimitedLight,
    icon:unlimitedDark,
    title:"Unlimited activities"
  },
  {
    hoverIcon:vocabLight,
    icon:vocabDark,
    title:"Vocabulary tools"
  },
  {
    hoverIcon:pdfLight,
    icon:pdfDark,
    title:"PDF creation"
  },
  {
    hoverIcon:rubricsLight,
    icon:rubricsDark,
    title:"Rubrics"
  },
  {
    hoverIcon:developmentLight,
    icon:developmentDark,
    title:"Professional development"
  },
  {
    hoverIcon:libraryLight,
    icon:libraryDark,
    title:"Library of resources"
  },
  {
    hoverIcon:videoLight,
    icon:videoDark,
    title:"Video uploads"
  }
]