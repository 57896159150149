import React,{useState} from 'react';
import { Link } from 'react-router-dom';
import styles from './StudentBoomerCard.module.scss'
import studentBoomer from 'Assets/auth/hangingBoomer.svg'
// import studentBoomer from 'Assets/auth/hangingBoomer.png'
// import { LazyLoadImage } from 'react-lazy-load-image-component';
// import 'react-lazy-load-image-component/src/effects/blur.css';


const StudentBoomerCard = () => {
  const [open, setOpen] = useState(false);

  return ( 
    <div className={open?`${styles.studentBoomerCard} ${styles.open}`:styles.studentBoomerCard} onClick={()=>setOpen(!open)}>
      <p>Are you a student??</p>
      <Link to='/auth/student'>Login Here</Link>
      <img src={studentBoomer} alt='boomer image' height={60}/>
    </div>
   );
}
 
export default StudentBoomerCard;