import React, { useLayoutEffect, useRef } from 'react';

import styles from './Community.module.scss';
import churchImg from '../../../../Assets/illustrations/church_sml.svg'
import schoolImg from '../../../../Assets/illustrations/school_sml.svg'
import suitcaseImg from '../../../../Assets/illustrations/suitcase.svg'
import familyImg from '../../../../Assets/illustrations/family.svg'
import arrow from '../../../../Assets/illustrations/arrow_circular1.svg'
import hands from '../../../../Assets/illustrations/hands.svg'
import { Link } from 'react-router-dom';

const Community = () => {

  const blockRef = useRef()
  const parishRef = useRef()
  const familyRef = useRef()
  const educatorRef = useRef()
  const adminRef = useRef()
  const refs = [parishRef, familyRef, educatorRef, adminRef]

  const handleChevron = e => {
    const container = e.target.parentNode.parentNode
    container.classList.toggle(styles.open)
    e.target.classList.toggle(styles.open)
  }


  const onScroll = () => {
    
    let topPosition = blockRef.current?.getBoundingClientRect().top;
    if (topPosition < (window.innerHeight/2)&&topPosition>0) {
      refs.forEach(i=>{
        const offset = Math.floor(Math.random()* 10)
        setTimeout(()=>{i.current.classList.add(styles.show);},offset*40)
      })
    } 
    
    if (
        topPosition>window.innerHeight
        // ||topPosition<`-${window.innerHeight}`
        ){
      refs.forEach(i=>{i.current.classList.remove(styles.show) })
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])


  return (
    <div ref={blockRef} className={`${styles.communityContainer} ${styles.sectionContainer}`}>
      <div className={styles.inner}>
        <div className={styles.cycle}>
          <div className={styles.arrow} src={arrow} alt="arrow" />
          <div className={styles.quadrant}>
            <div ref={parishRef} className={`${styles.cycleItemBlock} ${styles.floatingOffsetAfter} ${styles.parishes}`}>
              <div className={styles.initial}>
                <div className={styles.dot}>
                  <img src={churchImg} alt="icon" />
                </div>
                <h5>Parishes</h5>
                <div onClick={e=>handleChevron(e)} className={styles.chevron} />
              </div>
                <div className={styles.textWrapper}>
                  <p className={styles.text}><strong>Parishes</strong> flourish as their communities grow in faith, knowledge of God, and wisdom. Faith Journals can be a means to teach parish families, even in the most challenging of times. </p>
                </div>
            </div>
          </div>
          <div className={styles.quadrant}>
            <div ref={familyRef} className={`${styles.cycleItemBlock} ${styles.floatingAfter} ${styles.families}`}>
              <div className={styles.initial}>
                <div className={styles.dot}>
                  <img src={familyImg} alt="icon" />
                </div>
                <h5>Families</h5>
                <div onClick={e=>handleChevron(e)}  className={styles.chevron} />
              </div>
                <div  className={styles.textWrapper}>
                  <p className={styles.text}><strong>Families</strong> see their children immersed in the sacred Word and learning to apply it to daily life. Faith Journals offers a segue into home-based discussions of matters of faith and practice. </p>
                </div>
            </div>
          </div>
          <div className={styles.quadrant}>
            <div ref={adminRef} className={`${styles.cycleItemBlock} ${styles.floatingAfter} ${styles.administrators}`}>
              <div className={styles.initial}>
                <div className={styles.dot}>
                  <img src={suitcaseImg} alt="icon" />
                </div>
                <h5>Administrators</h5>
                <div onClick={e=>handleChevron(e)}  className={styles.chevron} />
              </div>
              <div className={styles.textWrapper}>
                <p className={styles.text}>By providing their schools with an easy-to-use, effective writing platform focused on faith, <strong>administrators</strong> gain the satisfaction of promoting quality student learning. They also increase the visibility and role of Scripture throughout the school community, deepening its spiritual identity. </p>
              </div>
            </div>
          </div>
          <div className={styles.quadrant}>
            <div ref={educatorRef} className={`${styles.cycleItemBlock} ${styles.floatingOffsetAfter} ${styles.educators}`}>
              <div className={styles.initial}>
                <div className={styles.dot}>
                  <img src={schoolImg} alt="icon" />
                </div>
                <h5>Educators</h5>
                <div onClick={e=>handleChevron(e)}  className={styles.chevron} />
              </div>
              <div className={styles.textWrapper}>
                <p className={styles.text}>Faith Journals’ flexibility allows <strong>educators</strong> to customize its use to the unique needs and specific interests of each student or class and integrate it with any curriculum. As a result, students experience a richer, more varied approach to religious education and personal faith. </p>
              </div>
            </div>
          </div>
          <a href={"mailto:info@faith-journals.com"} className={styles.getStartedBtn}>Speak with Us</a>
        </div>

        <div className={styles.content}>
          <img src={hands} alt="hands" />
          <p>“For the kingdom of heaven belongs to such as these” Matthew 19:14</p>
          <h3>A community effort to strengthen our relationship with God</h3>
          {/* <p>The entire community benefits in a unique way from children’s faith <br/>development through their Faith Journals reflections:</p> */}
        </div>
      </div>
    </div>
  )

}

export default Community;