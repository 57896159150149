import useOutsideClick from 'Helpers/useOutsideClick';
import React from 'react';
import { useRef } from 'react';
import { openInNewTab } from 'Helpers/uiFunctions'

import styles from './OnboardingModal.module.scss';

const OnboardingModal = ({ closeModal, userType }) => {

  const contRef = useRef()

  useOutsideClick(contRef, closeModal)

  const hadndleSelection = (version) => {

    const urls = {
      desktop_teacher:'https://es-courses-static.s3.amazonaws.com/fj-teachers-onboarding.pdf',
      mobile_teacher:'https://es-courses-static.s3.amazonaws.com/fj-teachers-nboarding-mobile.pdf',
      desktop_student:'https://es-courses-static.s3.amazonaws.com/student-onboarding-guide.pdf',
      mobile_student:'https://es-courses-static.s3.amazonaws.com/student-onboarding-guide-mobile.pdf',
    }
    openInNewTab(urls[`${version}_${userType}`])
    closeModal()

  }

  return (
    <div className={styles.container}>
      <div ref={contRef} className={styles.inner}>
        <div onClick={closeModal}  className={styles.close} />
        <h5>Which document do you wish to download?</h5>
        <div className={styles.options}>
          <div onClick={()=>hadndleSelection('desktop')} className={`${styles.option} ${styles.desktop}`}>
            <div className={styles.icon} />
            <p>Desktop version</p>
          </div>
          <div onClick={()=>hadndleSelection('mobile')} className={`${styles.option} ${styles.mobile}`}>
            <div className={styles.icon} />
            <p>Mobile version</p>
          </div>
        </div>
      </div>
    </div> 
  )

}

export default OnboardingModal;