import React, { useEffect, useState } from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';

import { sendToApi, sendToApiReturn } from '../../../../API/generalCalls';

import styles from './checkoutStyles.module.scss';
import CreditsDetailsBlock from './CreditsDetailsBlock';
import OrderDetailBlock from './OrderDetailBlock';
import ShippingDetailsBlock from './ShippingDetailsBlock';
import { useSelector } from 'react-redux';
import ConfirmationModal from 'Components/Bookstore/ConfirmationModal/ConfirmationModal';

const CheckoutStepThree = ({ setSummaryDetails, setStage, activityType, bookCode, setShowLoader, stage, isOpenClassOrder }) => {

  const bookDetails = useSelector(state => state.storeData.bookDetails)
  const summaryDetails = useSelector(state => state.storeData.summaryDetails)
  const userDetails = useSelector(state => state.storeData.userDetails)
  const schoolAddress = useSelector(state => state.storeData.bookDetails.schoolAddress)
  const [ apiResponse, setApiResponse ] = useState({})
  const [ showError, setShowError ] = useState({vis:false, text:''})
  const [ modalDismissed, setModalDismissed ] = useState(false)
  const stripe = useStripe();
  const elements = useElements();
  const { shippingOption, copies, items, address, books, creditDiscount } = summaryDetails;
  const { firstName, lastName, email } = address
  let payloadAddress = {...address}
  
  const payload = {
    code:bookCode,
    shippingPrice:!isOpenClassOrder?shippingOption.price:2.00,
    booksPrice:books,
    totalPrice:!isOpenClassOrder?books+shippingOption.price:books+2,
    quantity:copies,
    students:items,
    address:!isOpenClassOrder?payloadAddress:bookDetails.schoolAddress,
    creditsRedeemed: summaryDetails.creditsRedeemed*13,
    email:userDetails?.email,
    firstName:userDetails.firstName,
    lastName:userDetails.lastName,
  }


  const callApi = async (payload) => {
    console.log(payload)

    if (!localStorage.getItem("print_pi_id")) {
      
      setApiResponse(await sendToApiReturn(`store/print_order`, payload))
      
    } else {
      
      setApiResponse(await sendToApiReturn(`store/print_order?pi_id=${localStorage.getItem("print_pi_id")}`, payload))
    }
  }


  useEffect(()=>{ 

    console.log(userDetails)
    setApiResponse(null)

    if(summaryDetails.recallApi){
      console.log('recall')
      payload.creditsRedeemed = summaryDetails.creditsRedeemed*13
      payload.booksPrice = parseFloat(summaryDetails.books).toFixed(2)

      console.log(payload)

      callApi(payload)
    }
      
  },[summaryDetails.creditsRedeemed])

  useEffect(()=>{ 
    if(address.country_name!=='United States'){delete payloadAddress.state_name}
    if(stage!==4){callApi(payload)}

    console.log(bookDetails)
    console.log(userDetails)
    console.log(payload)
  },[])

  // useEffect(()=>{ 
    //this willneed to be reactivated when we implement the credit discounts, but was triggering extra API calls after a succesful purchase/when moving from step 2 to step 3. Deactivated for now
  //   console.log('creditDiscount')
  //   console.log(creditDiscount)
  //   console.log('books')
  //   console.log(books)

  //    if(stage!==4){callApi()}
  // },[creditDiscount])


  useEffect(()=>{
    if(apiResponse&&apiResponse.pi_id){
      localStorage.setItem('print_pi_id', apiResponse.pi_id);
    }
  },[apiResponse])


  const closeModal = () => {
    setModalDismissed(true)
    console.log('close modal')
  }

  const handleSubmit = async (event) => {
    setSummaryDetails({...summaryDetails, recallApi:false})
    console.log(payload)
    event.preventDefault();
    setShowLoader(true)
    setShowError({vis:false, text:''})

    if (!stripe || !elements) { return }
    
    const result = await stripe.confirmCardPayment(apiResponse.cs, {

      payment_method: {
        card: elements.getElement(CardElement),
        billing_details: {
          name: `${userDetails.firstName} ${userDetails.lastName}`,
          email:payload.email
        },
      }

      
    });  

    if (result.error) {
      setShowLoader(false)
      setShowError({vis:true, text:result.error.message})
      console.log(result.error.message);
    } else {
      
      if (result.paymentIntent.status === 'succeeded') {

        const payload =  {
          code:bookCode,
          shippingPrice:!isOpenClassOrder?shippingOption.price:2.00,
          booksPrice:parseFloat(summaryDetails.books).toFixed(2),
          totalPrice:!isOpenClassOrder?books+shippingOption.price:books+2,
          quantity:copies,
          students:items,
          address:!isOpenClassOrder?payloadAddress:bookDetails.schoolAddress,
          creditsRedeemed: summaryDetails.creditsRedeemed*13,
          email:userDetails?.email,
          firstName:userDetails.firstName,
          lastName:userDetails.lastName,
        }

        console.log('success!')
        console.log(payload)
        setStage(4)
        setShowLoader(false)
        sendToApi(`store/print_order_success?pi_id=${apiResponse.pi_id}`, payload, 'printPaymentResponse')
      }
    }
    
  };


  return (
    <div className={`${styles.checkoutStepThree} ${stage===4 && styles.purchaseComplete}`}>

      {
        (stage===4&&!modalDismissed) &&

          <div className={styles.confOverlay}>
            <ConfirmationModal heading='Payment Completed!' text='Thank you for your purchase.' closeAction={closeModal} />
          </div>
      }


      
      <div className={`${styles.orderDetailsDiv} ${stage===4 && styles.purchaseComplete}`}>
        <OrderDetailBlock 
          stepThree
          purchaseComplete={stage===4}
          setSummaryDetails={setSummaryDetails}
          summaryDetails={summaryDetails} 
          setStage={setStage} />
      </div>

      <ShippingDetailsBlock 
        isOpenClassOrder={isOpenClassOrder}
        purchaseComplete={stage===4}
        setSummaryDetails={setSummaryDetails}
        summaryDetails={summaryDetails} 
        setStage={setStage} 
        stepThree />

      {
        stage===3 &&

            <>

              <div className={styles.cardDetailsDiv}>
                <h4>Billing Details</h4>
                <p style={{margin:'15px 0 10px 0'}} >Please enter your card details below</p>
                <CardElement />
                {showError.vis && <p className={styles.paymentError}>{showError.text}</p>}
              </div>

            {
              userDetails.teacher &&
                <CreditsDetailsBlock 
                  payload={payload}
                  callApi={callApi}
                  activityType={activityType}
                  summaryDetails={summaryDetails}
                  setSummaryDetails={setSummaryDetails}
                  copies={summaryDetails.copies}
                  callApi={callApi}
                />

            }

              <div onClick={handleSubmit} className={`${styles.purchaseBtn} ${apiResponse === null && styles.disabled}`}>Purchase now</div>

            </>

        }
      

            

    </div>
  )

}

export default CheckoutStepThree;
