import React, { useRef } from 'react';

import styles from './Main.module.scss';
import journalCover from '../../../../Assets/bookCovers/cover_journal2.png'

import loader from '../../../../Assets/Icons/Spinner.svg'
import { useEffect } from 'react';
import { initialSummaryDetails } from '../../../../Redux/Reducers/initialValues';
import { useSelector } from 'react-redux';
import { getFromApi } from '../../../../API/generalCalls';

const Main = ({ code, getBookDetails, showLoader, showError, setStage, setBookDetails, setSummaryDetails, setUserDetails }) => {
  
  const userDetails = useSelector(state => state.storeData.userDetails);
  const inputRef = useRef()

  const handleSubmit = () => {
      if(inputRef.current.value.length===5){
        getBookDetails(inputRef.current.value)
      }
  }

  const getUserData = async () => {
    const details = await getFromApi('store/points_details')
    if(details)setUserDetails({
      ...details,
      teacher:true
    })
  }
  
  
  useEffect(()=>{
    // if(!userDetails||!userDetails.fullName){
    //   getUserData()
    // }
    setSummaryDetails( initialSummaryDetails )
    setStage(0)
  },[])

  return (
    <div className={styles.container}>
  

      <div className={styles.topBanner}>
        <div className={styles.content}>
          <h1>Faith Journals bookstore</h1>
          <p>Where each activity can be purchased as a soft cover journal, including reflection instructions and your own individual reflections.</p>
          <div className={styles.input}>
            <input onKeyUp={e=>{if (e.key === 'Enter') handleSubmit()}} ref={inputRef} autoFocus maxLength={5} type="text" name="codeInput" id="codeInput" defaultValue={code?code:null}  placeholder={"Book access code E.g: GHJB"}  onInput={(e)=>e.target.value=e.target.value.toUpperCase()}/>
            <div onClick={handleSubmit} className={styles.purchaseBtn}>Purchase now</div>
            {showError && <div className={styles.error}>No such code found, please check input</div>}
          </div>
        </div>
        <div className={`${styles.imgWrapper}`}>
          <img src={journalCover}  />
        </div>

            
          {showLoader &&

            <div className={styles.loadingOverlay}>
              <img src={loader} alt="loader" />
              <p>Retreiving book details...</p>
            </div>

          }

      </div>

    </div>
  )

}

export default Main;