import React, { useLayoutEffect, useRef, useState } from 'react';
import { featureData } from './featureData';
import teacherPic from 'Assets/homepage/jennifer.png'

import styles from './Features.module.scss';
import FeatureBlock from './FeatureBlock';

const Features = ({ noCarousel, carouselOnly, fullWidth }) => {

  const [ selectedIdx, setSelectedIdx ] = useState(0);
  const [ showBlocks, toggleShowBlocks ] = useState(false);
  const blockRef = useRef()


  const onScroll = () => {
    
    let topPosition = blockRef.current?.getBoundingClientRect().top;

    if (topPosition < (window.innerHeight/2)) {
      toggleShowBlocks(true)
    } 
    
    if (topPosition>window.innerHeight
        // ||topPosition<-650
        ){
      toggleShowBlocks(false)
      
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div ref={blockRef} className={`${styles.container} ${fullWidth&&styles.fullWidth} ${noCarousel && styles.paddBottom}`}>
      <div className={styles.inner}>

        {
          !carouselOnly &&

            <>
              <div className={styles.titleText}>
                <h4>A glimpse into our features</h4>
                {/* <p>The entire community benefits in a unique way from children’s faith development through their Faith Journals reflections:</p> */}
              </div>
      
              <div className={styles.featureBlocks}>
                {featureData.map((item,idx) => 
                <FeatureBlock showProp={showBlocks} key={idx} idx={idx} item={item} />
                )
                }
              </div>
              {
                !noCarousel &&
                  <h4 className={styles.subTitle}>Helping the community from their roots</h4>
              }
            
            </>
        }

        {
           !noCarousel &&
            <div className={`${styles.carouselWrapper} ${carouselOnly && styles.carouselOnly}`}>
              { carouselOnly && <h3>Making a difference</h3>}
              <div className={styles.carouselInner}>
            <div className={styles.arrows}>
              <div onClick={()=>setSelectedIdx(selectedIdx-1)} className={`${styles.arrow} ${styles.left} ${selectedIdx==0 && styles.inactive}`} />
              <div onClick={()=>setSelectedIdx(selectedIdx+1)} className={`${styles.arrow} ${styles.right} ${selectedIdx==1 && styles.inactive}`} />
            </div>
                <div className={styles.slidesContainer}>
                  <div className={`${styles.slides} ${selectedIdx == 1 && styles.two}`}>
                    <div className={styles.tesimonialBlock}>
                      <h1>“”</h1>
                      <h5>“Having taught for 25 years, the Faith Journals have revolutionized not only the way I teach the Bible but also how my students respond to God’s Word. The Faith Journals allow students to reflect on Scripture, write openly about Bible truths, and apply it to their daily lives. I am able to read my students’ entries and provide personalized responses, while also assessing their spiritual condition.Faith Journals have transformed my students and ignited them to follow and become the hands and feet of Jesus!”</h5>
                      <div className={styles.teacher}>
                        <img src={teacherPic} alt="teacher pic" />
                        <div className={styles.text}>
                          <p><strong>Jennifer W</strong></p>
                          <p><i>4th and 5th grade teacher - Jacksonville, FL</i></p>
                        </div>
                      </div>
                    </div>
                    <div className={styles.tesimonialBlock}>
                      <h1>“”</h1>
                      <h5>"Faith Journals, allowed my scholars to immerse themselves more deeply into reflections on the Stations of the Cross. Scholars were excited to begin writing because of the simplicity and seamlessness of its writing software. In addition, it gave the scholars an opportunity to earn points for writing to be used in customizing their avatars. Faith Journals provide an all-around engaging experience which scholars remember."</h5>
                      <div className={styles.teacher}>
                        {/* <img src={teacherPic} alt="teacher pic" /> */}
                        <div className={styles.text}>
                          <p><strong>Dan S</strong></p>
                          <p><i>6th, 7th and 8th grade teacher - Methuen, MA</i></p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        }


      </div>
    </div>
  )

}

export default Features;