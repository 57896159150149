import React, { useEffect, useReducer } from 'react';

import styles from './FeaturesCarousel.module.scss';
import { useRef } from 'react';

const actionTypes = {
  setSlide:"SET_SLIDE",
  setPercent:"SET_PERCENT",
}

function reducer(state, action){
  const { type, payload } = action;
  switch(type){
    case actionTypes.setSlide:
      return{...state, percent:0, slide:payload};
    case actionTypes.setPercent:
      return{...state, percent:payload};
    default:
      return state;
  }
}

const transitionSlide = (ref, instant) =>{
  const timeout = instant ? 10 : 1000;
  if(ref.current){
    if(instant)ref.current.classList.add(styles.hide)
    ref.current.classList.remove(styles.show)
    setTimeout(()=>{
      if(ref.current){
        if(instant)ref.current.classList.remove(styles.hide)
        ref.current.classList.add(styles.show)
      }
    },timeout)
  }
}

const FeaturesCarousel = ({ slideData, mainText, logoStr }) => {

  const [ state, dispatch ] = useReducer(reducer, { slide:0, percent:0 })
  const slideContRef = useRef()
  const logoClass = styles[logoStr]
  const narrowRadio = logoStr == "faithCademy"

  useEffect(()=>{

    const myInterval = setInterval(() => {

      const bar = document.getElementById(`bar${state.slide}`);
      if(bar)bar.style.width = `${state.percent}%`;

      //start CSS transition at 85%
      if(state.percent === 85) transitionSlide(slideContRef)

      if (state.percent < 100) { 
        //increment % when below 100%
        dispatch({type:actionTypes.setPercent, payload:state.percent+1})
      } else { 
        //switch slide at 100%
        clearInterval(myInterval)
        dispatch({type:actionTypes.setSlide, payload:state.slide === slideData.length-1 ? 0 : state.slide+1})
      } 

      
    }, 60)

    return ()=> {
        clearInterval(myInterval);
      };

  });

  useEffect(() => {
    console.log(logoStr);
    dispatch({ type:actionTypes.setSlide, payload:0 })
  }, [logoStr])

  const handleRadioClick = (idx) => {
    dispatch({ type:actionTypes.setSlide, payload:idx })
    transitionSlide(slideContRef, true)
  }

  return !slideData[state.slide] ? null : (
    <div className={styles.container}>
      <p className={`${styles.subhead}`}>{mainText}</p>
      <div className={`${styles.slideRadios}`}>
        { slideData.map((i, idx) => 
          <div 
            key={idx} 
            onClick={()=> handleRadioClick(idx) }
            className={`${styles.radioDiv} ${narrowRadio && styles.narrow} ${state.slide===idx && styles.selected}`}>
            <div className={`${styles.text}`}>
              { i.radioImage && <img src={i.radioImage} alt={`${i.slideTitle} icon`} />}
              <h6>{i.radioText ? i.radioText : i.slideTitle}</h6>
            </div>
            <div className={`${styles.progressBarContainer}`}>
              {state.slide === idx && <div style={{width:"0px"}} id={`bar${idx}`} className={`${styles.progressBar}`} />}
            </div>
          </div>
        )}
      </div>
      <div className={`${styles.slides} ${logoClass}`}>
        <div ref={slideContRef} className={`${styles.slideContainer} ${styles.show}`}>
          <div className={`${styles.imgCont}`}>
            <img src={slideData[state.slide].slideImg} alt="slide img" />
          </div>
          <div className={`${styles.text}`}>
            <h4>{slideData[state.slide].slideTitle}</h4>
            <p>{slideData[state.slide].slideText}</p>
          </div>
        </div>
      </div>
    </div>
  )

}

export default FeaturesCarousel;
