import { initialSummaryDetails } from "./initialValues"

export const storeData = (state = { stage: 0, bookDetails:{}, summaryDetails: initialSummaryDetails, userDetails:{} }, action) => {

  switch (action.type) {

    case 'SET_STAGE':
      return { ...state, stage: action.payload }

    case 'SET_BOOK_DETAILS':
      return { ...state, bookDetails: action.payload }

    case 'SET_SUMMARY_DETAILS':
      return { ...state, summaryDetails: action.payload }

    case 'SET_USER_DETAILS':
      return { ...state, userDetails: action.payload }

    case 'REMOVE_CHILD':
      
      const numberCopies = state.summaryDetails.items.find(x => x.studentId === action.studentId).quantity;
      const newItems = state.summaryDetails.items.filter(function( obj ) {
        return obj.studentId !== action.studentId;
      });

      const { copies } = state.summaryDetails

      return { ...state, summaryDetails: {...state.summaryDetails, items: newItems, copies:copies-numberCopies} }

      default: return state
  }
}