import React from 'react';
import { useState } from 'react';
import { useReducer } from 'react';

import styles from './AppSummaries.module.scss';
import FeaturesCarousel from './FeaturesCarousel/FeaturesCarousel';
import { fcSlideData, fjSlideData } from './SlideData';

const fjMainText = "Faith Journals Classrooms is a collaborative writing software that uses a creative pedagogy that has been proven to inspire students to write about Sacred Scripture and grow closer to God, while simultaneously enhancing their writing proficiency."

const fcMainText = "Faith Journals Courses provides a complete and simple solution to faith formation allowing private content delivery and interaction between instructors and group participants."

const actionTypes = {
  setPage:"SET_PAGE"
}

function reducer(state, action){
  const { type, payload } = action;
  switch(type){
    case actionTypes.setPage:
      console.log("SET_PAGE")
      return{...state, page:payload};
    default:
      return state;
  }
}

const AppSummaries = ({ summariesRef, academyTabSelected, dispatch, autoScrollTab }) => {

  const academyTab = autoScrollTab ==="academy" || academyTabSelected
  const [ page, setPage ] = useState( !academyTab ? 0 : 1 )

  const handlePage = (newPage) => {
    setPage(newPage)
    console.log(newPage)
    dispatch({type: "TOGGLE_HOW", payload:newPage===0});
    const newParam = `?tab=${newPage===0?"journals":"academy"}`
    window.history.pushState({path:newParam},'',newParam);

  }
  

  return (
    <div ref={summariesRef} className={styles.container}>
      <div className={`${styles.inner}`}>
        <div className={`${styles.tabRadios}`}>
          <div 
            onClick={()=>handlePage(0)} 
            className={`${styles.radio} ${page===0&&styles.selected}`}>
            {/* <h5>For schools</h5> */}
          </div>
          <div 
            onClick={()=>handlePage(1)} 
            className={`${styles.radio} ${page===1&&styles.selected} ${styles.parish}`}>
            {/* <h5>For institutions</h5>
            <h6>And parishes</h6> */}
          </div>
        </div>
        {
          page == 0 ? 

            <FeaturesCarousel
              slideData={fjSlideData}
              mainText={fjMainText}
              logoStr={"faithJournals"} />

          :

            <FeaturesCarousel
              slideData={fcSlideData}
              mainText={fcMainText}
              logoStr={"faithCademy"} />
              
        }
      </div>
    </div>
  )

}

export default AppSummaries;