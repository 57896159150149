import { addAnimation } from 'Helpers/addAnimation';
import React, { useRef, useState } from 'react';

import styles from './checkoutSteps.module.scss';
import FindChild from './FindChild';

const CheckoutStepOne = ({ activityType, summaryDetails, setSummaryDetails, setStage, bookDetails, isOpenClassOrder }) => {
  


  const searchRef = useRef()
  const [ showError, setShowError ] = useState(false)

  const recalculate = () => {

    let newTotal = 0;
    let array = [];
    const inputs = Array.from(document.querySelectorAll("[data-key='copyInput']"));
  
    inputs.forEach(i => {
      newTotal=newTotal+parseInt(i.value);
      if(i.value>0){ array.push({studentName:i.dataset.studentname, quantity:i.value, studentId:i.dataset.studentid}) }
    })
    
    setSummaryDetails({...summaryDetails, copies:newTotal, items:array})
  }

  const handleSubmit = () => {

    if(summaryDetails.copies<1&&searchRef.current){
      addAnimation(searchRef, 'shakeSlow', 500)
      setShowError(true)

    } else {
      
      // setStage(!isOpenClassOrder ? 2 : 3)
      setStage(2)

    }
  }


  return (
    <div className={styles.stepOneContainer}>


      <div className={styles.radios} onChange={(e)=>{
        setShowError(false)
        setSummaryDetails({...summaryDetails, type:e.target.value, books:0.00, copies:e.target.value==='premium'?0:1, items:[]})}
        }>
        {
          activityType === 'story' ?
          
            <>
              <label>
                <input defaultChecked={summaryDetails.type==='premium'} type="radio" name="bookType" id="premium" value="premium"/>
                <div className={`${styles.bookOption} ${styles.premium}`}>
                  <h3>Premium Book</h3>
                  <p>$12.99(USD)/book</p>
                </div>
              </label>

                {/* {
                  !isOpenClassOrder && */}
                      <label>
                        <input defaultChecked={summaryDetails.type==='standard'}  type="radio" name="bookType" id="standard" value="standard"/>
                        <div className={`${styles.bookOption} ${styles.standard}`}>
                          <h3>Standard Book</h3>
                          <p>$10.99(USD)/book</p>
                        </div>
                      </label>

                {/* } */}
              <p className={styles.subtext1}>* Includes your child's name on the cover and final chapter alternate ending.</p>
            </>
          :

            <label>
              <input defaultChecked={summaryDetails.type==='premium'}  type="radio" name="journalType" id="standard" value="premium" defaultChecked/>
              <div className={`${styles.bookOption} ${styles.journal}`}>
                <h3>Journal</h3>
                <p>$12.99(USD)/journal</p>
              </div>
            </label>


          }
      </div>

      {
        summaryDetails.type!=='standard' ?

             <FindChild
              recalculate={recalculate}
              searchRef={searchRef}
              setShowError={setShowError}
              setStage={setStage} 
              activityType={activityType} 
              bookCode={bookDetails.bookCode} 
              summaryDetails={summaryDetails} 
              setSummaryDetails={setSummaryDetails} 
            />

        :

        <div className={styles.copiesDiv}>
          <h3>Number of copies</h3>
          <input 
            onChange={recalculate} 
            type="number" 
            name="copies" 
            data-key={"copyInput"} 
            defaultValue={summaryDetails.copies} 
            min={0} 
          />
        </div>
      }

      
      <div onClick={handleSubmit} className={styles.nextBtn}>{!isOpenClassOrder?"Next: Shipping":"Next: Your details"}
        {showError && <div className={styles.error}>Please select your child's writing</div>}
      </div>
        {

          summaryDetails.type==='premium' && activityType === 'story' &&
          
            <p className={styles.subText}>** Alternate ending only included when the student has turned in a final chapter entry to the teacher.</p> 

        }
        {

          isOpenClassOrder && 
          
            <p className={`${styles.subText} ${styles.openClassOrder}`}>*** You will be invited to purchase additional copies of your child's book to be shipped to your home at a later date</p> 

        }


    </div>
  )

}

export default CheckoutStepOne;