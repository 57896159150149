import React, { useEffect, useState } from 'react';
import queryString from 'query-string'
import { useLocation } from 'react-router-dom';
import { postConfirmEmail } from '../../../API/Auth.service';

const ConfirmEmail = () => {
  const location = useLocation()
  const parsed = queryString.parse(location.search);
  const [e] = useState(parsed.e);
  const [c] = useState(parsed.c);
  const [serverErrors, setserverErrors] = useState();

  useEffect(()=>{
    confirmEmail()
  })

  useEffect(()=>{
    
  },[serverErrors])

  async function confirmEmail(){
    
    let errors = await postConfirmEmail({e:e,c:c})
    errors&&setserverErrors(errors)
  }

  return ( 
    <div>
      {serverErrors?serverErrors:'loading...'}
      <br/>
      {serverErrors&& 'redirecting you in 3s'}

    </div>
   );
}
 
export default ConfirmEmail;