import useSticky from 'Helpers/useSticky';
import React, { useState } from 'react';
import logo from 'Assets/logos/faith_logo_text.svg'

import styles from './Navbar.module.scss';
import { Link, NavLink } from 'react-router-dom';
import { GiHamburgerMenu } from 'react-icons/gi'
import { useWindowSize } from 'Helpers/useWindowSize';
import cross from 'Assets/illustrations/cross_blue.png';

const Navbar = ({ setShowLogin }) => {

  const [ resOpen, toggleResOpen ] = useState(false)
  const { isSticky } = useSticky()
  const { width } = useWindowSize()
  const isResponsive = width<900

  return !isResponsive ?

    <nav className={`${styles.navbar} ${isSticky && styles.sticky}`}>

      <Link to="/">
        <img className={styles.logo} src={logo} alt="logo" />
      </Link>

      <div className={styles.main}>
        <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/about"}>About Us</NavLink>
        <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/schools"}>Schools</NavLink>
        <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/parishes+institutions"}>Parishes/Institutions</NavLink>
        <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/pricing"}>Pricing</NavLink>
        <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/store"}>Bookstore</NavLink>
        {/* <NavLink activeClassName={styles.navActive} className={styles.navItem} to={"/contact"}>Contact Us</NavLink> */}
      </div>

      <div className={styles.right}>
        <div onClick={() => setShowLogin('login')} className={styles.signinBtn}>Log in</div>
        <div className={styles.signupBtn} onClick={()=> setShowLogin('signup')}>Get Started</div>
      </div>
    </nav>

 : 

    <nav 
      // onMouseLeave={()=>toggleResOpen(false)}
      className={`${styles.resNav} ${resOpen && styles.open} ${isSticky && styles.sticky}`}>
      <div className={styles.topRow}>
        <Link to="/"> <img className={styles.resLogo} src={logo} alt="logo" /> </Link>
        <div onClick={()=>toggleResOpen(!resOpen)} className={styles.button} />
      </div>
      <div className={styles.dropdown}>
        <img className={styles.logo} src={logo} alt="logo" />
        <Link to={"/about"}>About Us</Link>
        <Link to={"/schools"}>Schools</Link>
        <Link to={"/parishes+institutions"}>Parishes/Institutions</Link>
        <Link to={"/pricing"}>Pricing</Link>
        <Link to={"/?id=support"}>Bookstore</Link>
        <div className={styles.link} onClick={() => setShowLogin('login')}>Log in</div>
        <img className={styles.cross} src={cross} alt="cross" />
      </div>

    </nav>


}

export default Navbar;