import React from 'react';
import { useState } from 'react';

import styles from './TestimonialsCarousel.module.scss';
import img1 from  'Assets/homepage/carousel1.png'
import img2 from  'Assets/homepage/carousel2.png'
import img3 from  'Assets/homepage/carousel3.png'
import img4 from  'Assets/homepage/static_sheets.png'
import photo1 from  'Assets/homepage/carousel4.png'
import photo2 from  'Assets/homepage/carousel5.png'
import { useEffect } from 'react';
import { useRef } from 'react';
import { useLayoutEffect } from 'react';

const slideImgs = [ img1, img2, img3]

const testimonials = [
  "“This is quite the most rewarding and powerful tool I have used ... The conversations we are having about God and his Word have been incredible”",
  "“I want to tell you about someone, he is not fiction.... Jesus, The Messiah.... I hope you do Bible Journals...”",
  "“I love the Bible Journal. It is helping me so much. I didn't really read the Bible very much, but now that we have a Bible app we can read the Bible.”",
]

const TestimonialsCarousel = ({ noPadd, bgColor, staticState = true }) => {

  const [ slide, setSlide ] = useState( staticState ? 3 : 0)
  const contRef = useRef()
  const imgRef = useRef()
  const staticPhotoRef = useRef()
  const staticsheetsRef = useRef()
  const slideClasses = current =>  `${current == 0 && styles.paddRight} ${current > 0 && styles.sheets} ${current > 1 && styles.topAlign}`

  useEffect(()=>{
    if(!staticState){
      setTimeout(()=>{
        //timeout necessary to match the timeout handleArrow()
        if(imgRef.current&&slide!==null){
          imgRef.current.classList.remove(styles.focus)
          setTimeout(()=>{
            imgRef.current.classList.add(styles.focus)
          },10)
        }
      },50)

    }
  },[slide])

  useLayoutEffect(()=>{
    const commentsObserver = (target) => {
      const io = new IntersectionObserver((entries,observer) => {
        entries.forEach(entry =>{
          if(entry.isIntersecting){
            [staticPhotoRef?.current, staticsheetsRef?.current].forEach(i => i?.classList.add(styles.show))
            console.log('observer')
            observer.unobserve(entry.target)
          }
        })
      }, {threshold:[.75]})

      io.observe(target)
    }
    commentsObserver(contRef.current)

  },[])

  const handleArrow = (current, back) => {
    //slide must be set to null before the new value in order to 'reset' the css attributes of the img tag
    //without this step, we experience some very odd behaviour when transitioning, especially in Safari
    setSlide(null)
    setTimeout(()=>{
      if(back){
        setSlide(current==0?2:current-1)
      } else {
        setSlide(current==2?0:current+1)
      }
    },50)
  }
  

  return (
    <div style={bgColor && {backgroundColor:bgColor}} ref={contRef} className={`${styles.container} ${noPadd&&styles.noPadd} ${!staticState ? styles.carousel : styles.staticContainer} ${styles.sectionContainer}`}>
      <div className={`${styles.pinkBox} ${staticState && styles.staticBox}`}>

        {
          staticState ?
            <div className={`${styles.inner} ${styles.static}`}>


              <div className={`${styles.main}`}>

                <div className={`${styles.testimonialText} ${styles.staticText}`}>
                  <p>{testimonials[0]}</p>
                  <div className={`${styles.bottom}`}>
                    <p>Ms Walker</p>
                  </div>
                </div>

                <div className={`${styles.imgSwitch} ${styles.staticSlide}`}>
                    
                  <img className={`${styles.slideImg} ${styles.focus} ${styles.paddRight}`} src={slideImgs[0]} alt="slide image" />

                </div>
                <img ref={staticsheetsRef} className={`${styles.staticSheets}`} src={img4} alt="slide image" />
                
              </div>


            </div>
          :
            <div className={`${styles.inner}`}>
              <div className={`${styles.topBar}`}>
                <div className={`${styles.arrows}`}>
                  <div onClick={()=>handleArrow(slide, true)} className={`${styles.arrow} ${styles.left}`} />
                  <div onClick={()=>handleArrow(slide, false)} className={`${styles.arrow}`} />
                </div>
                <h3>What our teachers and students are saying</h3>
              </div>
              <div className={`${styles.main}`}>
                <div className={`${styles.testimonialText}`}>
                  <p>{slide!==null?testimonials[slide]:""}</p>
                  <div className={`${styles.bottom}`}>
                    <p>{slide==0?"Ms Walker":"Student"}</p>
                  <div className={`${styles.dashes}`}>
                    <div onClick={()=>setSlide(0)} className={`${styles.dash} ${slide==0&&styles.active}`} />
                    <div onClick={()=>setSlide(1)} className={`${styles.dash} ${slide==1&&styles.active}`} />
                    <div onClick={()=>setSlide(2)} className={`${styles.dash} ${slide==2&&styles.active}`} />
                  </div>
                  </div>
                </div>

                <div className={`${styles.imgSwitch}`}>
                  
                  {slide !== null && 
                    <img ref={imgRef} className={`${styles.slideImg} ${slideClasses(slide)}`} src={slideImgs[slide]} alt="slide image" />
                  }

                </div>
              </div>
            </div>
        }


      </div>
      <img ref={staticPhotoRef} className={`${styles.photo} ${styles.staticPhoto}`} src={photo1} alt="Class picture" />
      <img className={`${styles.photo} ${slide==0&&styles.show}`} src={photo1} alt="Class picture" />
      <img className={`${styles.photo} ${styles.two} ${slide==1&&styles.show}`} src={photo2} alt="Class picture" />
    </div>
  )

}

export default TestimonialsCarousel;