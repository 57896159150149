import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { postGoogleLoginStudent, postLogin, postLoginStudent, postSignUp, postSignUpStudent } from 'API/Auth.service';

import showPasswordFalse from 'Assets/auth/visibility-black-18dp.svg'
import showPasswordTrue from 'Assets/auth/visibility_off-black-18dp.svg'

import { GoogleLogin } from 'react-google-login';

import ls from 'local-storage'
import queryString from 'query-string'

import styles from './AuthForm.module.scss';

const AuthForm = ({ account, isSignup, prePopulate }) => {
  const location = useLocation()
  const { register, handleSubmit, getValues, errors } = useForm();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [checkbox, setcheckbox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [serverErrors, setServerErrors] = useState(undefined);
  const parsed = queryString.parse(location.search);
  const [app] = useState(parsed.app);
  const [invite] = useState(parsed.i);
  const [token] = useState(ls('t'))
  const [studentToken] = useState(ls('st'))
  const { email, firstName, lastName } = prePopulate

  useEffect(() => {
    autoLogin()
    //eslint-disable-next-line
  }, [token, studentToken])

  async function autoLogin() {
    let errors;

    if (account === 'student') {
      if (studentToken) {
        setLoading(true)
        errors = await postLoginStudent({ st: studentToken })
        errors && setServerErrors(errors)
        errors && setLoading(false)
      }

    } else {
      if (app) {
        if (token) {
          setLoading(true)
          errors = await postLogin({ t: token, app: app })
          errors && setServerErrors(errors)
          errors && setLoading(false)
        }

      } else {
        if (token) {
          setLoading(true)
          errors = await postLogin({ t: token })
          errors && setLoading(false)
        }
      }
    }
  }

  const renderFormHeader = () => {
    if (account === 'teacher' && !isSignup) return "Sign in to Faith Journals"
    if (account === 'teacher' && isSignup) return "Create your teacher account"
    if (account === 'student') return "Sign in to your student account"
    if (account === 'parent' && !isSignup) return "Sign in to your parent account"
    if (account === 'parent' && isSignup) return "Create your parent account"
    if (account === 'forgotPassword') return "Forgot Password?"
  }

  async function responseGoogle(response) {
    if (response && response.tokenId) {
      let errors = await postGoogleLoginStudent({ token: response.tokenId })
      errors && setServerErrors(errors)
    } else {
      setServerErrors(response.details)
    }
  }

  const renderSubtitle = () => {
    if (account === 'student') {
      return (
        <>
          <GoogleLogin
            clientId="55420424114-kkvuj1aecrvqurnauor2388spk419hdk.apps.googleusercontent.com"
            render={renderProps => (
              <div className={styles.googleBtn} onClick={renderProps.onClick} disabled={renderProps.disabled} />
            )}
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy={'single_host_origin'}
          />
          <div className={styles.separator}>
            <div className={styles.line} />
            <p className={styles.or}>Or</p>
            <div className={styles.line} />
          </div>
        </>
      )
    }
    if (account === 'parent' && isSignup) return <p className={styles.subtitle}>First up, let's get your Escribli parent account created</p>
    if (account === 'forgotPassword') return <p className={styles.subtitle}>Enter your email, and we will send you a password reset link</p>
  }
  const renderForgetPassword = () => {
    if (isSignup) return null
    return <Link to={account === 'student' ? '/auth/forgotPassword?student=true' : '/auth/forgotPassword'}> Forgot Password?</Link>
  }

  async function onSubmit(data) {
    console.log(invite)
    setLoading(true)
    
    if (!checkbox && isSignup) return setError(true)

    if (account === 'parent') {
      data.role = 5
      if (isSignup) {

        if (invite) {
          data.i = invite
          let errors = await postSignUp(data)
          errors && setServerErrors(Object.values(errors)[0])
          errors && setLoading(false)
        } else {
          let errors = await postSignUp(data)
          errors && setServerErrors(Object.values(errors)[0])
          errors && setLoading(false)
        }

      } else {
        let errors = await postLogin(data)
        errors && setServerErrors(errors)
        errors && setLoading(false)

      }
    }
    if (account === 'teacher') {
      data.role = 5
      if (isSignup) {
        if (invite) {
          data.i = invite
          let errors = await postSignUp(data)
          errors && setServerErrors(Object.values(errors)[0])
          errors && setLoading(false)
        } else {
          let errors = await postSignUp(data)
          errors && setServerErrors(Object.values(errors)[0])
          errors && setLoading(false)
        }

      } else {
        if (app) {
          data.app = app
          let errors = await postLogin(data)
          errors && setServerErrors(errors)
          errors && setLoading(false)

        } else {
          let errors = await postLogin(data)
          errors && setServerErrors(errors)
          errors && setLoading(false)
        }
      }
    }
    if (account === 'student') {
      data.role = 5
      if (isSignup) {
        let errors = await postSignUpStudent(data)
        errors && setServerErrors(errors)
        errors && setLoading(false)
      } else {
        let errors = await postLoginStudent(data)
        errors && setServerErrors(errors)
        errors && setLoading(false)
      }
    }

    // !checkbox && showSignUp ? setError(true) : history.push(urlToBeRedirectedto)
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <div className={styles.registerForm}>

          <div className={errors.email ? `${styles.inputDiv} ${styles.error}` : styles.inputDiv}>
            <label htmlFor="email">{account === 'student' ? `${!isSignup ? 'Username / Email' : 'Email'}` : 'Email'}</label>
            <input className={styles.input} type='text' name='email' defaultValue={email?email:null} placeholder="Peter@gmail.com" ref={register({ required: 'Email is required', pattern: { value:account === 'student' ? null : /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Email is invalid' } })} />
            <p className={styles.errorMessage}>{errors.email && errors.email.message} {serverErrors && serverErrors}</p>
          </div>

          {isSignup && <div className={errors.firstName ? `${styles.inputDiv} ${styles.error}` : styles.inputDiv}>
            <label htmlFor="firstName">First Name</label>
            <input className={styles.input} type='text' name='firstName' defaultValue={firstName?firstName:null}  placeholder="Peter" ref={register({ required: 'First name is required' })} />
            <p className={styles.errorMessage}>{errors.firstName && errors.firstName.message}</p>
          </div>}

          {isSignup && <div className={errors.lastName ? `${styles.inputDiv} ${styles.error}` : styles.inputDiv}>
            <label htmlFor="lastName">Last Name</label>
            <input className={styles.input} type='text' name='lastName' defaultValue={lastName?lastName:null}  placeholder="Parker" ref={register({ required: 'Last name is required' })} />
            <p className={styles.errorMessage}>{errors.lastName && errors.lastName.message}</p>
          </div>
          }


          <div className={styles.inputDiv}>
            <label htmlFor="password">Password{!isSignup && '/ Login Code'} {renderForgetPassword()}</label>
            <div className={errors.password ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
              <input className={styles.input} type={showPassword ? 'text' : 'password'} name='password' placeholder="Password" ref={register({ required: 'Password is required', minLength: { value: 5, message: 'Min password length is 5 ' } })} />
              <img src={showPassword ? showPasswordTrue : showPasswordFalse} alt='show password on off' onClick={() => setShowPassword(!showPassword)} />
            </div>
            <p className={styles.errorMessage}>{errors.password && errors.password.message}</p>
          </div>

          {isSignup &&
            <div className={styles.inputDiv}>
              <label htmlFor="confirmPassword">Confirm password</label>
              <div className={errors.confirmPassword ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
                <input className={styles.input} type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' placeholder="Password" ref={register({ validate: value => value === getValues('password') || "Passwords must match" })} />
                <img src={showConfirmPassword ? showPasswordTrue : showPasswordFalse} alt='show password on off' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
              </div>
              <p className={styles.errorMessage}>{errors.confirmPassword && errors.confirmPassword.message}</p>
            </div>
          }

          {isSignup && account === 'teacher' && <div className={errors.email ? `${styles.inputDiv} ${styles.error}` : styles.inputDiv}>
            <label htmlFor="secondEmail">Secondary Email</label>
            <p className={styles.secondEmailText}>Your school district's spam filters may keep you from receiving your activation email. To avoid this, you can add a second email below that will only be used to verify your main email address.</p>
            <input className={styles.input} type='text' name='secondEmail' placeholder="other@email.com" ref={register({ pattern: { value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Email is invalid' } })} />
            <p className={styles.errorMessage}>{errors.secondEmail && errors.secondEmail.message}</p>
          </div>
          }


        </div>

        {isSignup && <div className={styles.infoTerms}>
          <div className={styles.checkboxText}>
            <div className={error ? `${styles.checkbox} ${styles.error}` : checkbox ? `${styles.checkbox} ${styles.checked}` : styles.checkbox} onClick={() => { setcheckbox(!checkbox); setError(false) }} />
            <p>I have read and agree to the Escribli Terms and Privacy Policy.<br />
            Read our: <a href>Terms of Use</a> & <a href>Privacy Policies</a>
            </p>
          </div>
        </div>}





        <button className={serverErrors || errors.email || errors.password ? `${styles.greenBtn} ${styles.errors}` : styles.greenBtn} disabled={loading}>
          {loading ?
            <div className={styles.spinnerBox}>
              <div className={styles.pulseContainer}>
                <div className={styles.pulseBubble}></div>
                <div className={styles.pulseBubble}></div>
                <div className={styles.pulseBubble}></div>
              </div>
            </div> :
            isSignup ? "Sign up" : "Sign in"
          }
        </button>
      </form>
    )
  }

  return (
    <div className={styles.mainFormWrapper}>
      <div margin>
        <h2>{renderFormHeader()}</h2>
        {renderSubtitle()}
        {renderForm()}
      </div>
    </div>
  );
}

export default AuthForm;