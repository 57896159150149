import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { postForgot } from '../../../API/Auth.service';
import queryString from 'query-string'

import paperPlane from 'Assets/auth/paperPlane.svg'

import styles from './ForgotPassword.module.scss'

const ForgotPassword = () => {
  const { register, handleSubmit, errors } = useForm();
  const [serverErrors] = useState(undefined);
  const [modal, setModal] = useState(false);
  const search =  queryString.parse(window.location.search)
  

  async function onSubmit(data) {
    let errors = await postForgot(data, search.student)

    errors && setModal(true)
  }

  return (
    <div className={styles.forgotPassword}>
      <div>

        <div className={styles.header}>
          <h2>Forgot Password?</h2>
          <h3>Enter your email, and we will send you a password reset link</h3>

        </div>

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={styles.registerForm}>

            <div className={errors.email ? `${styles.inputDiv} ${styles.error}` : styles.inputDiv}>
              <label htmlFor="email">Email</label>
              <input className={styles.input} type='text' name='email' placeholder="example@gmail.com" ref={register({ required: 'Email is required', pattern: { value: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'Email is invalid' } })} />
              <p className={styles.errorMessage}>{errors.email && errors.email.message}{serverErrors && serverErrors}</p>
            </div>

          </div>

          <div className={serverErrors ? `${styles.greenBtn} ${styles.errors}` : styles.greenBtn}>
            <input type="submit" value="Reset Password" />
          </div>
        </form>
      </div>

      <div className={modal?styles.forgotModal:`${styles.close} ${styles.forgotModal}`}>
        <div className={styles.modalContainer}>
          <figure>
            <img src={paperPlane} alt='paper plane' />
          </figure>
          <h3>Check your inbox</h3>
          <p>It can take a few minutes to arrive. Don't forget to check the spam folder</p>
          <Link to="/" >Close</Link>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;