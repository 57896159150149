export const initialSummaryDetails = {
  creditsRedeemed:0,
  creditDiscount:0,
  copies:0, 
  books:0.00, 
  type:'premium', 
  items:[],
  address:{
    firstName:'',
    lastName:'',
    email:'',
    telephone:'',
    address_1:'',
    address_2:'',
    city:'',
    zip:'',
    country_name:'',
    state_name:''
  }
}
