import React, { useLayoutEffect, useRef } from 'react';

import styles from './Benefits.module.scss';
import flexible from 'Assets/Icons/flexible.png'
import interactive from 'Assets/Icons/interactive.png'
import engaging from 'Assets/Icons/engaging.png'

const Benefits = () => {



  const blockRef = useRef()
  const oneRef = useRef()
  const twoRef = useRef()
  const threeRef = useRef()
  const refs=[oneRef,twoRef,threeRef]

  const onScroll = () => {
    
    let topPosition = blockRef.current?.getBoundingClientRect().top;
    if (topPosition < (window.innerHeight/2)&&topPosition>0) {
      refs.forEach(i=>{
        const offset = Math.floor(Math.random()* 20)
        setTimeout(()=>{if(i.current)i.current.classList.add(styles.show);},offset*100)
      })
    } 
    
    if (
        topPosition>window.innerHeight
        // ||topPosition<`-${window.innerHeight}`
        ){
      refs.forEach(i=>{i.current.classList.remove(styles.show) })
    }
  }

  useLayoutEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [])

  return (
    <div ref={blockRef} className={styles.container}>
      <div className={styles.inner}>
        <h3 className={styles.benefitsTitle}>Endless possibilities for a lifelong learning journey.</h3>
        <div className={styles.cards}>
          <div ref={oneRef} className={styles.card}>
            <img src={flexible} alt="icon" />
            <h4 className={styles.cardTitle} >Flexible</h4>
            <p> Faith Journals can be integrated with any content material and is being used by all ages from 4th grade to high school and adult faith formation. It can be used online, in person, or in a hybrid format.</p>
          </div>
          <div ref={twoRef} className={styles.card}>
            <img src={interactive} alt="icon" />
            <h4 className={styles.cardTitle} >Interactive</h4>
            <p>Teachers can discuss with the student in real time, they can provide comments to each student's reflection, and students can also write synchronously in pairs. </p>
          </div>
          <div ref={threeRef} className={styles.card}>
            <img src={engaging} alt="icon" />
            <h4 className={styles.cardTitle} >Engaging</h4>
            <p>Faith Journals makes technology not a distraction but a meaningful means to develop a habit of reflection and prayer.</p>
          </div>
        </div>
        <a href={"mailto:info@faith-journals.com"} className={`${styles.contactBtn}`}>
          Speak with us
        </a>
      </div>
    </div>
  )

}

export default Benefits;