import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import styles from '../../Auth.module.scss'
import backArrow from 'Assets/auth/back.svg'
const AuthHeader = ({ account, isSignup, isReset }) => {

  const history = useHistory()

  const renderLink = () => {

    if (account === 'teacher') {
      return (
        isSignup ?
          <p>Already a member? <Link to='/auth/teacher'>Sign in now</Link></p>
          :
          <p>Not a member? <Link to='/auth/teacher/signup'>Sign up now</Link></p>
      )
    }
    if (account === 'parent') {
      return (
        isSignup ?
          <p>Already a member? <Link to='/auth/parent' >Sign in now</Link></p>
          :
          <p>Not a member? <Link to='/auth/parent/signup' >Sign up now</Link></p>
      )
    }
    if (account === 'student') {
      return (
        isSignup ?
          <p>Already a member? <Link to='/auth/student'>Sign in now</Link></p>
          :
          <p>Not a member? <Link to='/auth/student/signup'>Sign up now</Link></p>
      )
    }
  }


  return (
    <div className={styles.authHeader}>
      <div className={styles.back}>
        <div className={styles.goBack} onClick={()=> history.push('/')}> <img src={backArrow} alt=''/> Back</div>
      </div>
      <div className={styles.notAMember}>
        {renderLink()}
      </div>
    </div>
  )

}


export default AuthHeader;