import { openInNewTab } from 'Helpers/uiFunctions';
import React from 'react';
import play from 'Assets/Icons/playBtn.svg'

import styles from './Media.module.scss';

const MediaCard = ({item:{imgSrc, type, play, duration, title, bodyText, link}}) => {

  return (
    <div onClick={()=>openInNewTab(link)} className={styles.card}>
      <div className={`${styles.imgWrapper}`}>
        <img src={imgSrc} alt="card image" />
        {play && <div className={`${styles.play}`} />}
      </div>
      <div className={`${styles.details}`}>
        <h6 className={`${styles.type}`}>{type}</h6>
        {duration && <h6 className={`${styles.duration}`}>{duration}</h6>}
      </div>
      <h5>{title}</h5>
      <p>{bodyText}</p>
    </div>
  )

}

export default MediaCard;