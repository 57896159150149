import React from 'react';
import { useSelector } from 'react-redux';

import styles from './checkoutStyles.module.scss';

const ShippingDetailsBlock = ({ summaryDetails, setSummaryDetails, setStage, stepThree, isOpenClassOrder, purchaseComplete }) => {

  const {
    firstName,
    lastName,
    email,
    address_1,
    address_2,
    city,
    country_name,
    state_name,
    zip
  } = summaryDetails.address

  const schoolAddress = useSelector(state => state.storeData.bookDetails.schoolAddress)

  const handleEdit = () => {
    setSummaryDetails({...summaryDetails, creditsRedeemed:0})
    setTimeout(()=>{
      setStage(2); 
    },300)
  }

  return (
    <div className={`${styles.shippingDetailsBlock} ${stepThree && styles.stepThree} ${purchaseComplete && styles.purchaseComplete}`}>

      <div className={styles.colorTab}></div>
      <h3>Shipping details:</h3>

      {
        isOpenClassOrder ?

          <p>Class order purchases will be delivered at school</p>

      :

      <>
          {!purchaseComplete && <p onClick={handleEdit} className={styles.editBtn}>Edit Shipping</p>}
          <p>{firstName} {lastName}</p>
          <p>{email}</p>

      </>
      }

      <h4>Address:</h4>

      {
        isOpenClassOrder ? 
          <div className={styles.addressDiv}>
            <p>{schoolAddress.address_1}</p>
            {schoolAddress.address_2 && <p>{schoolAddress.address_2}</p>}
            <p>{schoolAddress.city}, {schoolAddress.zip}</p>
            <p>{schoolAddress.countryName === 'United States'&& `${schoolAddress.stateName}, `}{schoolAddress.countryName}</p>
          </div>

        :
          <div className={styles.addressDiv}>
            <p>{address_1}</p>
            {address_2 && <p>{address_2}</p>}
            <p>{city}, {zip}</p>
            <p>{country_name === 'United States'&& `${state_name}, `}{country_name}</p>
          </div>
      }


        {
          summaryDetails.shippingOption &&
          <p className={styles.shippedBy}> 
            <strong>Shipped via:</strong> {summaryDetails.shippingOption.name} {summaryDetails.shippingOption.tracking==='yes'&& '(tracked)'}
          </p>

        }
    </div>
  )

}

export default ShippingDetailsBlock;