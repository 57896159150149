import React, { useState } from 'react';
import queryString from 'query-string'
import { useForm } from 'react-hook-form';
import { Link, useLocation } from 'react-router-dom';
import { postResetPassword } from '../../../API/Auth.service';

import showPasswordFalse from '../../../Assets/Icons/visibility-black-18dp.svg'
import showPasswordTrue from '../../../Assets/Icons/visibility_off-black-18dp.svg'


import AuthHeader from '../Shared/AuthHeader/AuthHeader';
import styles from '../Auth.module.scss';
import teacherLogo from 'Assets/logos/logo_brown_text.svg';

const ResetPassword = () => {

  const { register, handleSubmit, getValues, errors } = useForm();
  const location = useLocation()
  const parsed = queryString.parse(location.search);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [c] = useState(parsed.c);
  const [loading, setLoading] = useState(false);


  async function onSubmit(data) {
    setLoading(true)
    postResetPassword({c: c, password:data.password, confirmPassword:data.confirmPassword})
  }

  return (

    <section className={styles.loginSection}>

      <div className={styles.leftSide}>
        
        <AuthHeader 
          account={'teacher'} 
          isReset={true}
          isSignup={false} />

        <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
          <div className={`${styles.registerForm} ${styles.confirmEmail}`}>
            <div className={styles.inputDiv}>
              <label htmlFor="password">Please set a password</label>
              <div className={errors.password ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
                <input className={styles.input} type={showPassword ? 'text' : 'password'} name='password' placeholder="Password" ref={register({ required: 'Password is required', minLength: { value: 5, message: 'Min password length is 5 ' } })} />
                <img src={showPassword ? showPasswordTrue : showPasswordFalse} alt='show password on off' onClick={() => setShowPassword(!showPassword)} />
              </div>
              <p className={styles.errorMessage}>{errors.password && errors.password.message}</p>
            </div>

            <div className={styles.inputDiv}>
              <label htmlFor="confirmPassword">Confirm password</label>
              <div className={errors.confirmPassword ? `${styles.iconInputDiv} ${styles.error}` : styles.iconInputDiv}>
                <input className={styles.input} type={showConfirmPassword ? 'text' : 'password'} name='confirmPassword' placeholder="Password" ref={register({ validate: value => value === getValues('password') || "Passwords must match" })} />
                <img src={showConfirmPassword ? showPasswordTrue : showPasswordFalse} alt='show password on off' onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
              </div>
              <p className={styles.errorMessage}>{errors.confirmPassword && errors.confirmPassword.message}</p>
            </div>



            <button className={errors.email || errors.password ? `${styles.submitPassBtn} ${styles.errors}` : styles.submitPassBtn} disabled={loading}>
              {loading ?
                <div className={styles.spinnerBox}>
                  <div className={styles.pulseContainer}>
                    <div className={styles.pulseBubble}></div>
                    <div className={styles.pulseBubble}></div>
                    <div className={styles.pulseBubble}></div>
                  </div>
                </div> :
                'Submit'
              }
            </button>
          </div>
        </form>


      </div>

      <div className={`${styles.rightSide} ${styles.teacher}`}>
        <div className={styles.whiteBlock}>
          <Link to='/'><img src={teacherLogo} alt="Faith Journals logo" /></Link>
          <h2>Educating minds, preparing hearts.</h2>
        </div>
      </div>
    </section>
  );
}

export default ResetPassword;