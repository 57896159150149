import React, { useEffect } from 'react';
import ls from 'local-storage'
import { useHistory } from 'react-router-dom';
import { postLogin } from '../../../API/Auth.service';

const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}

const Logout = () => {
  const history = useHistory()
  const admintToken = ls('at')
  
  useEffect(()=>{

    console.log('LOGOUT LOGOUT')
    
    if(admintToken){

      const int = setInterval(()=>{
        ls('t',admintToken)
        ls.remove('at')
        if(!ls('at')){postLogin({t:admintToken})}
      },500)
      
      return () => {
        clearInterval(int)
      }


    } else {
       
      const int = setInterval(()=>{
        ls.remove('t')
        if(!ls('t')){history.push('/')}
      },500)
      
      return () => {
        clearInterval(int)
      }
      
    }


  })

  return ( 
    <div style={styles}>
      Logging out ...
    </div>
   );
}
 
export default Logout;
