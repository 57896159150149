import React, { useEffect } from 'react';
import ls from 'local-storage'
import { useHistory } from 'react-router-dom';
import { postLogin } from '../../../API/Auth.service';

const styles = {
  width: "100%",
  height: "100vh",
  display: "flex",
  alignItems: "center",
  justifyContent: "center"
}


const StudentLogout = () => {
  const history = useHistory()
  const teacherToken = ls('t')

  useEffect(()=>{
    ls.remove('st')
    if(teacherToken){
      postLogin({t:teacherToken})
    }
    
    history.push('/auth/student')
  })

  return ( 
    <div style={styles}>
      Logging out ...
    </div>
   );
}
 
export default StudentLogout;