import React from 'react';
import { useState } from 'react';
import styles from './Homepage.module.scss';
import OnboardingModal from './OnboardingModal/OnboardingModal';
import Benefits from './Sections/Benefits/Benefits';
import Community from './Sections/Community/Community';
import Hero from './Sections/Hero/Hero';
import TestimonialsCarousel from './Sections/TestimonialsCarousel/TestimonialsCarousel';
import Media from './Sections/Media/Media';
import AppSummaries from './Sections/AppSummaries/AppSummaries';
import HowItWorks from './Sections/HowItWorks/HowItWorks';
import Features from './Sections/Features/Features';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useReducer } from 'react';
import Collaboration from './Sections/Collaboration/Collaboration';

function reducer(state, action){
  const { type, payload } = action;
  switch(type){
    case "TOGGLE_HOW":
      return { ...state, howItWorks:!state.howItWorks};
    case "TOGGLE_MODAL":
      return { ...state, modal:payload};
    default:
      return state
  }
}

const Homepage = ({ academyTabSelected, autoScrollTab }) => {

  const summariesRef=useRef()

  const [ show, dispatch ] = useReducer(reducer, { 
    modal:false, 
    howItWorks:!academyTabSelected 
  })

  useEffect(()=>{

    if(autoScrollTab) summariesRef.current.scrollIntoView();

  },[autoScrollTab])

  return (
    <div className={styles.container}>
      <Hero />

      <AppSummaries 
        dispatch={dispatch}
        summariesRef={summariesRef} 
        autoScrollTab={autoScrollTab}
        academyTabSelected={academyTabSelected} />
      
      <Collaboration />

      <Benefits />

      <HowItWorks 
        dispatch={dispatch}
        isVisible={show.howItWorks} />

      <Features noCarousel />

      <Community />

      <TestimonialsCarousel noPadd />
      
      <Media />

      { show.modal && 
        <OnboardingModal 
          closeModal={()=>dispatch({type:"TOGGLE_MODAL", payload:null})}
          userType={show.modal} /> }

    </div>
  )

}

export default Homepage;

