import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import styles from './FeatureBlock.module.scss';

const FeatureBlock = ({showProp, item: {title, icon, hoverIcon}}) => {

  const blockRef=useRef()
  const [ show, toggleShow] = useState(false)

  useEffect(()=>{

    if (showProp) {
      let offset = Math.floor(Math.random()* 8)
      setTimeout(()=>{
        toggleShow(true)
      },offset*80)

    } else {
      toggleShow(false)
      
    }
  },[showProp])


  return (
    <div ref={blockRef} className={`${styles.featureBlock} ${show && styles.show}`}>
      <img src={icon} className={`${styles.featureIcon}`} alt={title} />
      <img src={hoverIcon} className={`${styles.hoverIcon}`} alt={title} />
      <p>{title}</p>
    </div>
  )

}

export default FeatureBlock;