
import axios from 'axios';

const host = window.location.host 
const env = host ==='localhost:3011' ? 'dev' : host ==='sand.faith-journals.com' ? 'stage' : 'prod'
const baseURL = env === 'prod' ? 
`https://backapi.faith-journals.com/api` :
`https://backapi.sand.faith-journals.com/api`

export const getFromApi = async (query) => {

  const token = localStorage.getItem('t');
  console.log(`${baseURL}/${query}`)
  console.log({headers: { 'Authorization': token || '' } })
  
  try {
    
    const { data } = await axios.get(`${baseURL}/${query}`, {headers: { 'Authorization': token || '' } } )
    
    return data;
    
  } catch (error) {
    console.log(error)
  }
}

export const sendToApi = async (query, newData, action, details) =>{

  const token = localStorage.getItem('t');
  console.log(`${baseURL}/${query}`)
  console.log(newData)
  
  const { data } = await axios.post(`${baseURL}/${query}`, newData, {headers: { 'Authorization': token || '' }})

 try {

   return data
   
 } catch (error) {
  console.log(error)
 }
}


export const sendToApiReturn = async ( query, newData ) =>{

  const token = localStorage.getItem('t');
  console.log(`${baseURL}/${query}`)
  console.log(newData)

  
  const { data } = await axios.post(`${baseURL}/${query}`, newData, {headers: { 'Authorization': token || '' }})
  console.log(newData)
  console.log(data)

  return data
}


