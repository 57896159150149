import book from '../../../../Assets/illustrations/book_open_blue.png';
import dove from '../../../../Assets/illustrations/dove_blue.png';
import plant from '../../../../Assets/illustrations/plant.png';
import slideOneImg from '../../../../Assets/homepage/read_write1.png'
import slideTwoImg from '../../../../Assets/homepage/read_write2.png'
import slideThreeImg from '../../../../Assets/homepage/read_write3.png'

import fcSlideOneImg from '../../../../Assets/homepage/fc_slide1.png'
import fcSlideTwoImg from '../../../../Assets/homepage/fc_slide2.png'
import fcSlideThreeImg from '../../../../Assets/homepage/fc_slide3.png'
import fcSlideFourImg from '../../../../Assets/homepage/fc_slide4.png'
import fcSlideFiveImg from '../../../../Assets/homepage/fc_slide5.png'


export const fjSlideData = [
  {
    slideTitle:"Read", 
    radioImage:book,
    slideImg: slideOneImg,
    slideText:"Students read Scriptures passages, writings from the Saints, or spiritual exercises provided by the teacher or spiritual director."
  },
  {
    slideTitle:"Reflect & write", 
    radioImage:dove,
    slideImg: slideTwoImg,
    slideText:"Students reflect and write on the material provided. Instructors can guide and respond to students’ work in real time."
  },
  {
    slideTitle:"Grow", 
    radioImage:plant,
    slideImg: slideThreeImg,
    slideText:"Bringing students closer to God and walking with them in their spiritual journey."
  },
]

export const fcSlideData = [
  {
    radioText:"Create", 
    slideTitle:"Create your own course", 
    radioImage:null,
    slideImg: fcSlideOneImg,
    slideText:"Add lessons and chapters and the content for each, that can include images, videos or attachments. Lessons can be added at any time before or during the program."
  },
  {
    radioText:"Publish", 
    slideTitle:"Publish and share", 
    radioImage:null,
    slideImg: fcSlideTwoImg,
    slideText:"Each course/program has a unique link for participants to join. Participants will be able to see the program information page with the description, chapters and lessons outline, promotional video and other information added."
  },
  {
    radioText:"Monitor", 
    slideTitle:"Monitor participants", 
    radioImage:null,
    slideImg: fcSlideThreeImg,
    slideText:"Students reflect and write on the material provided and instructors can monitor students’ work in real time."
  },
  {
    radioText:"Accompany", 
    slideTitle:"Accompany participants", 
    radioImage:null,
    slideImg: fcSlideFourImg,
    slideText:"Instructors can also review the participants reflections and provide feedback and guidance, a great way to engage with them and accompany them in their spiritual journey! *(Programs can also be set as private)."
  },
  {
    radioText:"Engage", 
    slideTitle:"Engage in discussion", 
    radioImage:null,
    slideImg: fcSlideFiveImg,
    slideText:"All participants and instructors can interact with each other in the “Discussion Board”, specific for each course/program."
  },
]
