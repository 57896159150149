import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import App from './App';
import { env } from 'env';
import store from 'storeConfig';

const stripeKey = (env === 'prod' ? 'pk_live_51JZGxAH8mRTYLOz5s91L7tW1B9NnidWHl3TnxFUcyByAVqlBu22e7IrMZvqxndtPUf4sRsTvaGA2MAwJJV29m9fr00NWljFwGC' : 'pk_test_51JZGxAH8mRTYLOz5uAiJwW9c24BFhL3RrZmfTzGvDMvQHcby1F9xNPr352ywyFBcTFIxO2S55ihHM1cCM1WpzbBn00ZyCCQrdb')

const stripePromise = loadStripe(stripeKey);

ReactDOM.render(
  <Provider store={store}>
  <React.StrictMode> 
    <BrowserRouter>
        <Elements stripe={stripePromise}>
          <App />
        </Elements>
    </BrowserRouter>
  </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);

