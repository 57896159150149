import React from 'react';
import { Link } from 'react-router-dom';

import styles from './Footer.module.scss';
import logo from '../../../Assets/logos/faith_logo_text_white.svg';
import instaLogo from '../../../Assets/logos/insta.svg';
import escribliLogo from '../../../Assets/logos/whiteLogoEscribli.svg';
import linkedInLogo from '../../../Assets/logos/linkedin.svg';
import cross from '../../../Assets/illustrations/cross.svg';
import { openInNewTab } from 'Helpers/uiFunctions';

const Footer = () => {

  return (
    <div className={styles.footerContainer}>

      <div className={styles.logosContainer}>
        <img src={logo} alt="Faith Journals logo" />
        <div className={styles.socials}>
          <a target={"_blank"} href={"https://www.linkedin.com/company/escribli/about/"} className={styles.insta}>
            <img src={instaLogo} alt="Instagram" />
          </a>
          <a target={"_blank"} href={"https://www.linkedin.com/company/escribli/about/"}  className={styles.insta}>
            <img src={linkedInLogo} alt="LinkedIn" />
          </a>
        </div>
      </div>
      <div className={styles.links}>
        <Link to={'/'}>Home</Link>
        <Link to={'/about'}>About Us</Link>
        <Link to={'/schools'}>Schools</Link>
        <Link to={'/parishes+institutions'}>Parishes/Institutions</Link>
        <Link to={'/store'}>Bookstore</Link>
        <Link to={'/home/privacy-policy'}>Privacy Policy</Link>
        <Link to={'/home/terms'}>Terms</Link>
        <Link to={'/home/cookie-policy'}>Cookie Policy</Link>
      </div>

      <div className={`${styles.smallprint}`}>
        <p className={styles.copyright}>All rights reserved © 2021 </p>
        <p className={`${styles.copyright}`}>A company of <img onClick={()=>openInNewTab("https://escribli.com/")} className={`${styles.escribli}`} src={escribliLogo} /> </p>  
      </div>
      <img src={cross} alt="cross" className={styles.cross} />
    </div>
    
  )
  
}

export default Footer;



{/* <div className={styles.policyLinks}>
<Link to={'/home/privacy-policy'}>Privacy Policy</Link>
<Link to={'/home/terms'}>Terms</Link>
<Link to={'/home/cookie-policy'}>Cookie Policy</Link>
</div> */}